import { useSelector, useDispatch } from "react-redux";
import EditableInput from "../../utils/EditableInput";
import ImageUpload from "../../utils/ImageUpload";
import ClearAnswers from "../../utils/ClearAnswers";
import {modifyCampaignData} from '../../../firebaseActions.mjs';
import { updateCampaignDB } from "../../utils/UpdateFunctions";
import { Notification } from "../../utils/Misc";
import { editCampaign } from "../../../actions/UserActions";
import { current } from "@reduxjs/toolkit";


function CampaignSettings(props) {
	const dispatch = useDispatch();
	const {currentCampaign, currentCampaignId} = useSelector( state => state.user );
	const {closeCb} = props;
	const {multiVoting, restartText, canClear, clearText} = currentCampaign;
	const {title, description, image} = currentCampaign.shareSecondary;
	const updateShareSecondary = (key, value, extra) => {
		currentCampaign['shareSecondary'][key] = value;
		let o = {
			share: {...currentCampaign['shareSecondary'], ...{[key]: value}}
		}
		modifyCampaignData(currentCampaignId, o)
	}
	const updateCampaign = (key, value) => {
		console.log(key, value);
		currentCampaign[key] = value;
		console.log(currentCampaign);
		updateCampaignDB(currentCampaignId, { [key]: currentCampaign[key] }, dispatch, editCampaign)
		.catch((e) => {
			if (e?.errorName == 'CampaignLockedError') {
				Notification("The campaign is locked", 'error');
			} else {
				Notification("An unexpected error occured, Please try again later!", 'error');
			}
		})
	}
	return (
		<div className="modal-overlay active modal__right">
			<div className="modal modal--lg active animate__animated animate__slideInRight modal--100">
				<div className="modal__header">
					<div className="modal__header-title">
						<h3 className="mtop--10">
							<span className="material-icons vm--align font--30">
								settings
							</span>
							&nbsp;
							Campaign Settings
						</h3>
					</div>
					<div className="close-modal modal--x" onClick={ () => { closeCb(false) }}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body">
						<div className="appearance__item">
							<label className="text--bold">Clear answers</label>
							<ClearAnswers currentCampaign={currentCampaign} currentCampaignId={currentCampaignId} />
						</div>
						{(!canClear||multiVoting) && <div className="appearance__item mtop--20">
							<div className="form__group">
								<div className="form__group-label text--bold">
									Vote on same device multiple times?
								</div>
								<div className="form__group-content">
									<EditableInput type="checkbox" isSwitch={true} item="multiVoting" startValue={multiVoting ? multiVoting : false} updateAction={updateCampaign} />
								</div>
							</div>
							<div className="mtop--10 flex--50">
								<label>Text</label>
								<EditableInput type="text" item="restartText" startValue={(restartText===null||restartText===undefined)?"Restart":restartText} updateAction={updateCampaign}/>
							</div>
						</div>}
						{(!multiVoting||clearText) && <div className="appearance__item mtop--20">
							<div className="form__group">
								<div className="form__group-label text--bold">
									Allow users to clear their answers?
								</div>
								<div className="form__group-content">
									<EditableInput type="checkbox" isSwitch={true} item="canClear" startValue={canClear ? canClear : false} updateAction={updateCampaign} />
								</div>
							</div>
							<div className="mtop--10 flex--50">
								<label>Text</label>
								<EditableInput type="text" item="clearText" startValue={(clearText===null||clearText===undefined)?"Clear":clearText} updateAction={updateCampaign}/>
							</div>
						</div>}
						<div className="appearance__item mtop--20">
							<label className="text--bold">Share campaign</label>
							<>
								<div className="appearance__item mleft--10	mtop--10 flex--50">
										<label>Title</label>
										<EditableInput type="text" item="title" characterCount={150} startValue={title} updateAction={updateShareSecondary}/>
									</div>
								<div className="appearance__item mleft--10	mtop--10">
									<label className="text--bold">Meta description</label>
									<EditableInput type="textarea" item="description" characterCount={300} startValue={description} updateAction={updateShareSecondary}/>
								</div>
								<div className="appearance__item mleft--10	mtop--10">
									<label className="text--bold">Meta image</label>
									<ImageUpload campaign_id={currentCampaignId} file={image} item="image" updateAction={updateShareSecondary} />
								</div>
							</>
						</div>
					</div>
					<div className="modal__footer text--right ">
						<button className="btn btn-primary" onClick={ () => { closeCb(false) }}>
							<i className="fi fi-rr-check vm--align"></i>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CampaignSettings;
