import {useSelector} from 'react-redux';

import TextElementOptions from "./elementOptions/TextElementOptions";
import SubmitElementOptions from "./elementOptions/SubmitElementOptions";
import NumericPredictionElementOptions from "./elementOptions/NumericPredictionElementOptions";
import OpenEndElementOptions from "./elementOptions/OpenEndElementOptions";
import ImageElementOptions from "./elementOptions/ImageElementOptions";
import SpacerElementOptions from "./elementOptions/SpacerElementOptions";
import RegistrationElementOptions from "./elementOptions/RegistrationElementOptions";
import LinkElementOptions from "./elementOptions/LinkElementOptions";
import PollElementOptions from "./elementOptions/PollElementOptions";
import ImageChoiceElementOptions from "./elementOptions/ImageChoiceElementOptions";
import CategorizedPollElementOptions from "./elementOptions/CategorizedPollElementOptions";
import WheelOfPrizesElementOptions from "./elementOptions/WheelOfPrizesElementOptions";
import SoccerLineUpSelectorElementOptions from "./elementOptions/SoccerLineUpSelectorElementOptions";
import SlideShowElementOptions from "./elementOptions/SlideShowElementOptions";
import PoweredByElementOptions from "./elementOptions/PoweredByElementOptions";
import MvpElementOptions from "./elementOptions/MvpElementOptions";
import ScratchElementOptions from "./elementOptions/ScratchElementOptions";
import UGCElementOptions from "./elementOptions/UGCElementOptions";
import QuizResultsOptions from "./elementOptions/QuizResultsOptions";

import CampaignLocked from "../../utils/CampaignLocked";
import selectOptionImage from "../../../images/undraw_decide_re_ixfw.svg";
import IFrameElementOptions from './elementOptions/IFrameElementOptions';
function CampaignOptions() {
	const {currentElement} = useSelector(state => state.user);
	return (
		<div>
            { currentElement &&
                <CampaignLocked>
                    { currentElement.type === 'text' && <TextElementOptions/>}
                    { currentElement.type === 'submitButton' && <SubmitElementOptions/>}
                    { currentElement.type === 'numericPrediction' && <NumericPredictionElementOptions/>}
                    { currentElement.type === 'freeText' && <OpenEndElementOptions/>}
                    { currentElement.type === 'image' && <ImageElementOptions/>}
                    { currentElement.type === 'spacer' && <SpacerElementOptions/>}
                    { currentElement.type === 'link' && <LinkElementOptions/>}
                    { currentElement.type === 'iframe' && <IFrameElementOptions/>}
                    {/* { currentElement.type === 'social' && <SocialElementOptions/>} */}
                    { currentElement.type === 'registrationForm' && <RegistrationElementOptions/>}
                    { currentElement.type === 'poll' && <PollElementOptions isQuiz={false}/>}
                    { currentElement.type === 'multipleChoice' && <PollElementOptions isQuiz={true}/>}
                    { currentElement.type === 'quizResults' && <QuizResultsOptions/>}
                    { currentElement.type === 'imageChoice' && <ImageChoiceElementOptions/>}
                    { currentElement.type === 'categorizedPoll' && <CategorizedPollElementOptions/>}
                    { currentElement.type === 'wheelOfPrizes' && <WheelOfPrizesElementOptions/>}
                    { currentElement.type === 'wheelOfPrizesPreview' && <WheelOfPrizesElementOptions isPreview={true} />}
                    { currentElement.type === 'soccerLineupSelector' && <SoccerLineUpSelectorElementOptions/>}
                    { currentElement.type === 'slideShow' && <SlideShowElementOptions/>}
                    { currentElement.type === 'powered' && <PoweredByElementOptions/>}
                    { currentElement.type === 'mvp' && <MvpElementOptions />}
                    { currentElement.type === 'scratch' && <ScratchElementOptions/>}
                    { currentElement.type === 'ugc' && <UGCElementOptions/>}
                </CampaignLocked>
            }
            { !currentElement &&
                <div className="mtop--35 text--center">
                    <img alt="select image" src={selectOptionImage} width="320" />
                    <h3>Select an element from the left to edit it.</h3>
                    <span className="material-icons font--40">
                        roundabout_left
                    </span>
                </div>
            }
        </div>
	);
}

export default CampaignOptions;
