import { useState, useEffect } from "react";
import './scss/NumericPredictionOption.scss';
function NumericPredictionOption({data, textPosition, min, max, value, sending, elementErrors, onChange, showOptionsText}) {
	const [err, setErr] = useState(false);
	if(value===undefined) value='';
	console.log('~NumericPredictionOption', data.id)
	function setData(e){
		setErr(false);
		let targetValue = parseInt(e.target.value);
		if(isNaN(targetValue)) targetValue = '';
		else{
			if(min!==undefined) targetValue = Math.max(targetValue, min);
			if(max!==undefined) targetValue = Math.min(targetValue, max);
		}
		console.log('* NumericPredictionOption sends:', data.id, targetValue)
		onChange(data.id, targetValue);
	}
	const getInputStyling = () => {
		let className = "NumericPredictionOption";
		if(err)	className += ' has-error';
		return className;
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function(e){
			setErr(true);
		});
	}, [elementErrors])
	return (
		<div className={getInputStyling()}>
			{ showOptionsText && (textPosition==='above') ? <div className="NumericPredictionOption__text">{data.text}</div> : ''}
			<img src={data.image} alt={data.text} className="NumericPredictionOption__image"/>
			{ showOptionsText && (textPosition==='below') ? <div className="NumericPredictionOption__text">{data.text}</div> : ''}
			<input type="number" pattern="[0-9]*" inputMode="numeric" className="NumericPredictionOption__input" onChange={setData} min={min} max={max} step="1" value={value} placeholder="-" disabled={sending}/>
			<div className="err__msg">This field cannot be empty</div>
		</div>
	);
};
export default NumericPredictionOption;
