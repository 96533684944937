import { useEffect, useState, useCallback, useMemo } from "react";
import "./scss/WheelOfPrizes.scss";
/* import WheelComponent from 'react-wheel-of-prizes'; */
import { Wheel } from 'react-custom-roulette'
import WheelOfPrizesWon from "./WheelOfPrizesWon";
import WheelOfPrizesLost from "./WheelOfPrizesLost";
import novibetImage from './novibet.png';

function WheelOfPrizes({ data, value, sending, elementErrors, onChange, onSubmit, goToNextPage, result, primaryTextColor }) {
	console.log('~WheelOfPrizes', data.id, value, elementErrors)
	const pathname = window.location.pathname;
	const campaignId = pathname.split('/')[1];	
	const [screen, setScreen] = useState(null);
	const [spinning, setSpinning] = useState(false);
	const [loading, setLoading] = useState(false);
	const [mappedData, setMappedData] = useState(null);
	const [sliceIndex, setSliceIndex] = useState(null);
	const [spinResult, setSpinResult] = useState(null);

	const isNovibet = useMemo(() => {
		return ['pxYhIDJ3nAZ0ppKCZLce', 'CkYDNrwjZPJqs2DjCzNP', 'Focaphg4gEUycfwgvoXQ'].includes(campaignId);
	}, [campaignId])
	const shouldApplyWheelStyling = () => {
		return data.image || isNovibet
	}

	const defaultColors = [
		"#0466c8",
		"#023e7d",
		"#001845",
		"#33415c",
		"#5bba6f",
		"#137547",
		"#fbb13c",
		"#ffd97d",
		"#a4133c",
		"#ff758f",
		"#3a0ca3",
		"#3f37c9",
		"#4895ef" 
	];

	const textColors = useMemo(() => {
		return data?.slices.reduce((acc, slice) => [...acc, ...[slice.textColor || '#fff']], [])
	}, [data]);

	const spin = useCallback(() => {
		setLoading(true);
		onSubmit(null, false);
	}, [onSubmit])
	
	const setData = useCallback((data) => {
		setSpinning(false);
		if (spinResult['won']) {
			setScreen('won')
		} else {
			setScreen('lost')
		}
	}, [spinResult])

	const borderImageStyling = useMemo(() => {
		if(!shouldApplyWheelStyling) return {};

		return {
			backgroundImage: `url(${data.borderImage || '/images/common/border.png'})`,
			backgroundSize: `${data.borderImageSize || 130}%`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% center'
		}
	}, [data])

	useEffect(() => {
		const d = data?.slices.map((slice, idx) => {
			let o = {
				option: slice.text,
				style: {
					backgroundColor: slice.color || defaultColors[idx]
				},
			}
			if (slice.image && slice.image !== '/images/common/placeholder.png') {
				o['image'] = { uri: slice.image, sizeMultiplier: slice.imageSizeMultiplier};
			}
			return o
		})
		setMappedData(d);
		onChange(data.id, true);
	}, [data])

	useEffect(() => {
		if (!result) return;
		Object.entries(result['responses']).forEach(([key, value]) => {
			Object.entries(value['elements']).forEach(([elKey, elValue]) => {
				if (!elValue['spinResult']) return;
				setSpinResult(elValue['spinResult']);
				setSliceIndex(parseInt(elValue['spinResult']['sliceIndex']));
				setTimeout(() => {
					setLoading(false);	
					setSpinning(true);
				}, 150);
			})
		})
	}, [result])
	return (
		<div style={{ marginTop: data?.marginTop }} className="wheel_ofprizes">
			{screen === null &&
				<>
					{mappedData &&
						<div className="wheel__inner">
							<div className={`wheel__inner-wheel ${ shouldApplyWheelStyling && 'wheel__inner-wheelBordered'}`} style={borderImageStyling}>
								{data.centerImage && <img src={data.centerImage} className="wheel__inner-wheelNovibet"/> }
								{ isNovibet && <img src={novibetImage} className="wheel__inner-wheelNovibet"/>}
								<Wheel
									mustStartSpinning={spinning}
									prizeNumber={sliceIndex}
									data={mappedData}
									backgroundColors={[defaultColors[0], defaultColors[1]]}
									textColors={textColors}
									onStopSpinning={setData}
									spinDuration={1}
									startingOptionIndex={0}
									disableInitialAnimation={true}
									innerRadius={10}
									perpendicularText={data.perpendicularText || false}
									pointer={{ src: 'https://plus.unsplash.com/premium_photo-1673466949685-260c87954301?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80' }}
								/>
								<span className="material-icons wheel__inner-marker" style={{ color: data.buttonColor }}>
									place
								</span>
							</div>

						</div>
					}
					<div className="text--center mtop--20">
						{(!loading && !spinning) &&
							<div className="btn btn-primary mtop--10 btn-lg btn-100" onClick={spin} style={{ background: data.buttonColor, color: data.textColor }}>
								{data.buttonText || 'Spin now'}
							</div>
						}
						{loading &&
							<div className="waveform">
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
								<div className="waveform__bar"></div>
							</div>
						}
					</div>
				</>
			}
			{screen === 'won' && <WheelOfPrizesWon next={goToNextPage} styling={data} data={spinResult} />}
			{screen === 'lost' && <WheelOfPrizesLost next={goToNextPage} styling={data} data={data} primaryTextColor={primaryTextColor} />}
		</div>
	);
};
export default WheelOfPrizes;
