import {useState, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import EditableInput from "../../../../utils/EditableInput";
import {getDefaultElement} from "../../CampaignAddDefaults";
import { updateElement } from '../../../../utils/UpdateFunctions';
import { editElement } from '../../../../../actions/UserActions';
import WheelOfPrizesSlice from './WheelOfPrizesSlice';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import WheelOfPrizesBulkEditPrizes from './WheelOfPrizesBulkEditPrizes';
import PrizeSimulationModal from '../../../../utils/PrizeSimulationModal';

function WheelOfPrizesGeneral({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy, locked }) {
	const dispatch = useDispatch();
	const { slices, participationProbability } = elementCopy;
	const MAX_SLICES = 16;
	const [dragLoader, setDragLoader] = useState(false);
	const [bulkEditPrizes, setBulkEditPrizes] = useState(false);
	const [simulationModal, setSimulationModal] = useState(false);

	const _updateSubmit = () => {
		return updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const update = (key, value, extra) => {
		if(key === 'participationProbability') value = value * 10000;
		elementCopy[key] = value;
		_updateSubmit();
	}
	const addSlice = () => {
		const newSlice = getDefaultElement('wheelSlice');
		elementCopy['slices'] = [...elementCopy['slices'], ...[newSlice]];
		_updateSubmit();
	}
	const onSortEnd = async (data) => {
		setDragLoader(true);
		const toBeMoved = slices[data.oldIndex];
		if(!toBeMoved) return false;
		let filteredSlices = slices.filter((slice) => slice.id !== toBeMoved.id)
		filteredSlices.splice(data.newIndex, 0, toBeMoved);
		elementCopy['slices'] = filteredSlices;
		await _updateSubmit();
		setDragLoader(false);
	}
	const deletePrize = (prize, sliceIdx) => {
		elementCopy.slices[sliceIdx].prizes = elementCopy.slices[sliceIdx].prizes.filter( p =>  p.id !== prize.id)
		_updateSubmit();
	}
	const updatePrize = (key, value, extra) => {
		return new Promise((resolve, reject) => {
			if(key === 'probability') value = value === null ? null : (value * 10000);
			const {sliceIdx, prize_id} = extra
			elementCopy.slices[sliceIdx].prizes.forEach((prize, idx) => {
				if(prize.id === prize_id){
					if ((key === 'stock') && (value === null)) {
						prize.stock = null;
						if (['spread', 'reserve'].includes(prize.strategy?.strategy)) prize.strategy = null;
					}else{
						prize[key] = value
					}
				}
			})
			resolve(true);
		})
	}
	const SortableItem = useMemo( () => SortableElement(({ item, i, index }) => (
		<WheelOfPrizesSlice
			index={i}
			key={item.id}
			slice={item}
			elementCopy={elementCopy}
			currentCampaignId={currentCampaignId}
			_updateSubmit={_updateSubmit}
			deletePrize={deletePrize}
			updatePrize={updatePrize}
			locked={locked}
		/>
	)), [elementCopy, currentCampaignId] );
	const SortableList = useMemo( () => SortableContainer(({ items }) => {
		console.log("items", items)
		return (
			<ul className="sortable__container sortable__inline">
				{
					items.map((value, i) => (
						<SortableItem key={value.id} i={i} index={i} item={value} />
					))
				}
			</ul>
		);
	}), [] );
	return (
		<div className="pt-4">
			<button className="btn btn-primary" onClick={()=>setSimulationModal(true)}>Simulate Prize Distribution</button>
			{simulationModal && <PrizeSimulationModal currentElement={{...currentElement, participationProbability, slices}} closeCb={setSimulationModal}/>}
			<div className="form__group">
				<div className="form__group-label">
					Participation Probability
					<div className="text--muted font--12">This is the probability of the user participating on the spin and winning a prize.</div>
				</div>
				<div className="form__group-content">
					<EditableInput item="participationProbability" type="number"  min="0" startValue={participationProbability ? participationProbability / 10000 : 0} updateAction={update} placeholder={'50'} />
					<div className="text--warning font--12">Min: 0%-Max: 100%</div>
				</div>
			</div>
			<div className="mtop--15 flex flex--row justify--spread">
				<button className="btn btn-primary" onClick={addSlice} disabled={elementCopy.slices.length >= MAX_SLICES || locked}>
					<span className="material-icons">add</span>
					Add Slice
				</button>
				<button className="btn btn-primary-soft text--normal" onClick={() => setBulkEditPrizes(true)} disabled={elementCopy.slices.length >= MAX_SLICES}>
					<span className="material-icons">emoji_events</span>
					Edit Prizes
				</button>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Edit each slice</label>
				<div className="card card__primary text--center flex flex--row">
					<span className="material-icons font--30">info</span>
					<div className="mleft--20">
						<div className="font--12">
							<div className="btn btn-warning-pastel btn-sm">
								<span className="material-icons font--15">
									emoji_events
								</span>
							</div>
							Slices <b>with prizes</b> in them are labeled as winning slices.
						</div>
						<div className="font--12">
							<div className="btn btn-secondary-pastel btn-sm">
								<span className="material-icons font--15">
									flaky
								</span>
							</div>
							Slices <b>with no prizes</b> in them are labeled as losing slices.
						</div>
					</div>
				</div>
				<div className="mtop--15">
					{  dragLoader && <div className="page__selector-loader text--center"><span className="loader"></span></div> }
					{ !dragLoader && <SortableList axis="y" useDragHandle items={slices} onSortEnd={onSortEnd} /> }
				</div>
			</div>
			{bulkEditPrizes && <WheelOfPrizesBulkEditPrizes
				setModal={setBulkEditPrizes}
				slices={slices}
				updatePrize={updatePrize}
				_updateSubmit={_updateSubmit}
				deletePrize={deletePrize}
				currentCampaignId={currentCampaignId}
				locked={locked}
			/>}
		</div>
	);
}

export default WheelOfPrizesGeneral;
