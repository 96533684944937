import { useState, useEffect, useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import './scss/FormFieldSignature.scss';

function FormFieldSignature({ data, value, sending, elementErrors, onChange }) {
	const [err, setErr] = useState(false);
	const canvas = useRef();
	const required = data.required;
	const defaultProps = {
		lazyRadius: 0,
		onChange: setData,
		brushRadius: 1,
		brushColor: "#000",
		/* catenaryColor: "#0a0302", */
		gridColor: "rgba(150,150,150,0.17)",
		hideGrid: true,
		canvasHeight: 250,
		/* saveData: saveData, */
		gridSizeX: 25,
		gridSizeY: 25,
		gridLineWidth: 0.5,
		enablePanAndZoom: true,
		mouseZoomFactor: 0.01,
		zoomExtents: { min: 0.8, max: 2 },
	};

	console.log('~FormFieldSignature', data)
	function saveData(v) {
		console.log(v);
	}
	function setData() {
		setErr(false);
		if (!canvas.current) return;

		const base64Image = canvas.current.getDataURL();
		console.log('* FormFieldSignature sends:', data.id, base64Image);
		onChange(data.id, base64Image);
	}
	const getInputStyling = () => {
		let className = "FormFieldSignature mtop--15";
		if (err) className += ' input--error';
		return className;
	}
	const clear = () => {
		if (!canvas.current) return;
		canvas.current.clear();
		onChange(data.id, null);
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])
	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<div className="FormFieldSignature__canvas">
				<CanvasDraw
					ref={canvas}
					{...defaultProps}
				/>
				<span className="material-icons text--danger FormFieldSignature__clear" onClick={clear}>
					clear
				</span>
			</div>
			{/* <input onChange={(e) => setData(e.target.value)} value={value} disabled={sending} placeholder={data.placeholder} type={inputType} /> */}
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default FormFieldSignature;
