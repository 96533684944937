import "./scss/Image.scss";
function Image({data}) {
	const getImageStyling = () => {
		if(!data.background) return;
		let d = data.background;
		let o = {
			"backgroundPosition": d.position ? d.position.x+" "+d.position.y : "center",
			"backgroundRepeat": d.repeat ? d.repeat : 'no-repeat',
			"backgroundSize": d.size ? d.size : 'contain',
			"backgroundImage": d.url ? "url("+d.url+")" : '',
			"height": data.size ? data.size : '100px',
			"width": "100%",
			"marginTop": data.marginTop ? data.marginTop : '',
		}
		return o;
	}
	return (
		<div>
			{ data.url ?
				<a href={data.url} target={data.target}>
					<div className="image__element" style={getImageStyling()}></div>
				</a>
				:
				<div className="image__element" style={getImageStyling()}></div>
			}
		</div>

	);
};
export default Image;
