import "./scss/ImageChoice.scss";
function ImageChoice({ data, value, sending, elementErrors, onChange }) {
	console.log('~ImageChoice', data.id, value, elementErrors)
	console.log(data)
	if (!Array.isArray(value)) value = [];
	const setData = (newValue) => {
		let dt = [...value];
		const index = dt.indexOf(newValue);
		if (index < 0) {
			if (data.max === 1) {
				dt = [newValue];
			} else {
				dt.push(newValue)
			}
		} else {
			dt.splice(index, 1);
		}
		console.log('* ImageChoice sets and sends:', data.id, value, '->', dt)
		onChange(data.id, dt);
	};
	const getStyling = () => {
		return {
			marginTop: data?.marginTop,
			background: data?.background,
			color: data?.textColor,
			borderRadius: '4px',
			padding: '5px',
		}
	}
	return (
		<div style={getStyling()} className="poll">
			{data.question && <div className="poll__header" dangerouslySetInnerHTML={{ __html: data.question }} />}
			{data.layout === "row" &&
				<div className="image__choice-container image__choice-containerRow">
					{data?.options.map((option, index) => {
						const selected = value.includes(option.id);
						return (
							<div key={`option__${index}`} className={"image__choice" + (selected ? " image__choice-selected" : "")} onClick={() => setData(option.id)}>
								<span className="material-icons font--25 check">{selected ? 'radio_button_checked' : 'radio_button_unchecked'}</span>
								{data.textPosition === "above" && <div>{option.showText === false ? '' : option.text}</div>}
								<img src={option.image} className="img__responsive" />
								{data.textPosition === "below" && <div>{option.showText === false ? '' : option.text}</div>}
							</div>
						)
					})}
				</div>
			}
			{data.layout === "column" &&
				<div className="image__choice-container image__choice-containerColumn">
					{data?.options.map((option, index) => {
						const selected = value.includes(option.id);
						return (
							<div key={`option__${index}`} className={"image__choice" + (selected ? " image__choice-selected" : "")} onClick={() => setData(option.id)}>
								<span className="material-icons font--25 check">{selected ? 'radio_button_checked' : 'radio_button_unchecked'}</span>
								{data.textPosition === "above" && <div>{option.showText === false ? '' : option.text}</div>}
								<img src={option.image} className="img__responsive" />
								{data.textPosition === "below" && <div>{option.showText === false ? '' : option.text}</div>}
							</div>
						)
					})}
				</div>
			}
			{elementErrors?.errors.map((error, idx) => <div key={`error_${idx}`} className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default ImageChoice;
