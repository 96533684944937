import { useEffect, useState } from "react";

function SoccerLineUpSelectorPlayer({ shirtColor, value, position, index, shirtImage, players, groupedPlayers, setData, currentMap, errors }) {
    const [selectionScreen, setSelectionScreen] = useState(false);

    const setPlayerToPosition = (player) => {
        console.log(`Setting ${player.id} on position ${position} and index ${index}`);
        setData(position, index, player.id)
        setSelectionScreen(null);
	};
    const resetPlayerPosition = () => {
        setData(position, index, null)
        setSelectionScreen(null);
    }
    const getPositionValue = () => {
        const f = currentMap[position].find( p => p.position === index);
        if(f.playerId){
            const player = players.find((player) => player.id === f.playerId);
            return <div>
                <img src={player.image} alt='player' />
                <div className="font--12 field__player-name">{player.name}</div>
            </div>;
        }else{
            return (
                <div className={`field__player-empty ${errors && 'field__player-emptyError'}`}>
                    {(position==='goal')&&<div><span className="field__player-tag">GK</span></div>}
                    {!shirtImage && <i className="fa-solid fa-shirt" style={{ color: shirtColor ? shirtColor : '#feee' }}></i>}
                    {shirtImage && <img src={shirtImage} alt="shirt" />}
                    <div className="btn btn-secondary btn-md lineup__player-add">
                        <span className="material-icons">
                            add
                        </span>
                    </div>
                    <div className="field__player-emptyErrorText">
                        Pick
                    </div>
                </div>
            );
        }
    }
    const playerIsAlreadySelected = (player_id) => {
        if(!currentMap) return false;

        let res = false;
        Object.keys(currentMap).forEach((key) => {
            if (typeof currentMap[key] === 'string') return;
            currentMap[key].forEach((position) => {
                if (position.playerId === player_id) res = true;
            })
        });
        return res
    }
	return (
        <>
            <div className="formation__grid-column lineup__player" onClick={() => setSelectionScreen(true)}>
                {getPositionValue()}
            </div>
            { selectionScreen && 
                <div className="lineUp__modal animate__animated animate__slideInRight">
                    <div className="flex flex--row">
                        <span className="material-icons font--30 pointer" onClick={() => {setSelectionScreen(false)}}>
                            arrow_back_ios
                        </span>
                        <div className="btn btn-danger-soft float--right" onClick={resetPlayerPosition}>
                            <span className="material-icons vm--align font--20">
                                restart_alt
                            </span>
                            <span>Reset</span>
                        </div>
                    </div>
                    <div className="flex flex--column mtop--20">
                        {groupedPlayers.map( (group, idx) => {
                            return (
                                <div key={`lineup__group__${idx}`}>
                                    { group.players.length > 0 && <h4>{group.title}</h4> }
                                    <div className="lineup__player-selection mtop--20">
                                        {group.players.map((player, idx) => {
                                            return (
                                                <div key={`player__${idx}`} className={`lpPlayer-selection ${playerIsAlreadySelected(player.id) && 'lpPlayer-selection-selected'}`} onClick={() => setPlayerToPosition(player)}>
                                                    <span className="material-icons font--25 checked">radio_button_checked</span>
                                                    <span className="material-icons font--25 unchecked">radio_button_unchecked</span>
                                                    <img src={player.image} alt="player" className="img__responsive" />
                                                    <div className="lpPlayer-selection__info" >
                                                        <div className="lpPlayer-selection__info-text">{player.name}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
	);
};
export default SoccerLineUpSelectorPlayer;
