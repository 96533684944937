const elementTypes = Object.freeze([
	{
		"type": "numericPrediction",
		"icon": "123",
		"text": "Prediction",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "poll", "imageChoice", "mvp", "soccerLineUpSelector", "wheelOfPrizes"],
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "registrationForm",
		"icon": "how_to_reg",
		"text": "Registration",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["numericPrediction", "poll", "imageChoice", "mvp", "soccerLineUpSelector", "wheelOfPrizes", "registrationForm", "submitButton"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": true,
		"isDecorative": false,
	},
	{
		"type": "imageChoice",
		"icon": "collections",
		"text": "Image Choice",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "mvp", "soccerLineUpSelector", "wheelOfPrizes"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "mvp",
		"icon": "sports_gymnastics",
		"text": "MVP",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "poll", "imageChoice", "soccerLineUpSelector", "wheelOfPrizes"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "soccerLineupSelector",
		"icon": "sports_soccer",
		"text": "Line-Up Selector",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "poll", "imageChoice", "mvp", "wheelOfPrizes"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "poll",
		"icon": "how_to_vote",
		"text": "Poll",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "mvp", "soccerLineUpSelector", "wheelOfPrizes"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "multipleChoice",
		"icon": "quiz",
		"text": "Multiple Choice",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["quizResults"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "quizResults",
		"icon": "checklist",
		"text": "Quiz Results",
		"allowedPageTypes": ["normal", "endPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": ["multipleChoice"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": true,
		"isDecorative": true,
	},
	{
		"type": "wheelOfPrizes",
		"icon": "interests",
		"text": "Wheel Of Prizes",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": true,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "poll", "imageChoice", "mvp", "soccerLineUpSelector", "wheelOfPrizes", "submitButton"],
		"hasWizard": true,
		"extraPagesBefore": ["registrationForm", "wheelOfPrizesPreview"],
		"unique": true,
		"isDecorative": false,
	},
	{
		"type": "wheelOfPrizesPreview",
		"icon": "interests",
		"text": "Wheel Of Prizes (Fake Spin)",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "poll", "imageChoice", "mvp", "soccerLineUpSelector", "wheelOfPrizes", "submitButton"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": true,
		"isDecorative": false,
	},
	{
		"type": "scratch",
		"icon": "casino",
		"text": "Scratch To Win",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": true,
		"conflictingElementTypes": ["registrationForm", "numericPrediction", "poll", "imageChoice", "mvp", "soccerLineUpSelector", "wheelOfPrizes", "submitButton", "scratch"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "submitButton",
		"icon": "ads_click",
		"text": "Submit",
		"allowedPageTypes": ["normal"],
		"allowedOnLocked": false,
		"conflictingElementTypes": ["registrationForm", "submitButton", "wheelOfPrizes", "scratch"],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": false,
	},
	{
		"type": "image",
		"icon": "image",
		"text": "Image",
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "spacer",
		"icon": "expand",
		"text": "Spacer",
		"conflictingElementTypes": [],
		"hasWizard": false,
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "link",
		"icon": "link",
		"text": "Link",
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "iframe",
		"icon": "filter_frames",
		"text": "IFrame",
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "text",
		"icon": "html",
		"text": "Text",
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "slideShow",
		"icon": "web_stories",
		"text": "Slide Show",
		"allowedPageTypes": ["normal", "endPage", "expiredPage"],
		"allowedOnLocked": true,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": false,
		"isDecorative": true,
	},
	{
		"type": "ugc",
		"icon": "web_stories",
		"text": "User Generated Content (Beta)",
		"allowedPageTypes": ["endPage"],
		"allowedOnLocked": false,
		"conflictingElementTypes": [],
		"hasWizard": false,
		"extraPagesBefore": [],
		"unique": true,
		"isDecorative": false,
	},
]);
export const elementTypeMap = new Map(elementTypes.map((t)=>[t.type, t]));
const simpleDecorativeElementTypes = Object.freeze(elementTypes.filter((e)=>e.isDecorative&&!e.unique&&!e.conflictingElementTypes.length).map((e)=>e.type));
const nonDecorativeElementTypes = Object.freeze(elementTypes.filter((e)=>!e.isDecorative).map((e)=>e.type));
const campaignTypes = [
	{
		"type": "numericPrediction",
		"icon": "123",
		"text": "Prediction",
		"allowedElementTypes": ["numericPrediction", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "registrationForm",
		"icon": "how_to_reg",
		"text": "Registration",
		"allowedElementTypes": ["registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "imageChoice",
		"icon": "collections",
		"text": "Image Choice",
		"allowedElementTypes": ["imageChoice", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "mvp",
		"icon": "sports_gymnastics",
		"text": "MVP",
		"allowedElementTypes": ["mvp", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "soccerLineupSelector",
		"icon": "sports_soccer",
		"text": "Line-Up Selector",
		"allowedElementTypes": ["soccerLineupSelector", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "poll",
		"icon": "how_to_vote",
		"text": "Poll",
		"allowedElementTypes": ["poll", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "wheelOfPrizes",
		"icon": "interests",
		"text": "Wheel Of Prizes",
		"allowedElementTypes": ["wheelOfPrizes", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "scratch",
		"icon": "casino",
		"text": "Scratch To Win",
		"allowedElementTypes": ["scratch", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "quiz",
		"icon": "quiz",
		"text": "Quiz",
		"allowedElementTypes": ["multipleChoice", "registrationForm", "quizResults", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "wheelOfPrizesPreview",
		"icon": "interests",
		"text": "Wheel Of Prizes (Fake Spin)",
		"allowedElementTypes": ["wheelOfPrizes", "registrationForm", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": ["registrationForm", "wheelOfPrizes", "wheelOfPrizesPreview"],
		"nonDeletablePages": ["registrationForm", "wheelOfPrizes", "wheelOfPrizesPreview"],
	},
	{
		"type": "ugc",
		"icon": "web_stories",
		"text": "User Generated Content (Beta)",
		"allowedElementTypes": ["ugc", "submitButton", ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
	{
		"type": "custom",
		"icon": "interests",
		"text": "Custom Type",
		"allowedElementTypes": [...nonDecorativeElementTypes, ...simpleDecorativeElementTypes],
		"nonDraggablePages": [],
		"nonDeletablePages": [],
	},
];
export const campaignTypeMap = new Map(campaignTypes.map((t)=>[t.type, t]));

export const getPageElements = (type = null) => {
	if (type) {
		return elementTypes.find((el) => el.type === type);
	}
	return elementTypes;
};

export function getCampaignAllowedElements(campaignType) {
	const out = [];
	const ct = campaignTypeMap.get(campaignType);
	if (ct) {
		ct.allowedElementTypes.forEach((type)=>{
			if (elementTypeMap.has(type)) out.push(elementTypeMap.get(type));
		});
	}
	return out;
}
export function getCampaignTypes() {
	return campaignTypes;
}
