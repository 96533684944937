import React, {useMemo} from 'react'
import {SortableHandle} from 'react-sortable-hoc';
import Dropdown from '../../../utils/Dropdown';
import { elementTypeMap, campaignTypeMap } from '../../../utils/PageElements';

function Page({page, i, goTo, duplicatePage, currentCampaign, getPageCss, setDeleteModal}) {

	const getPageEnding = page => {
		const endings = ['th', 'st', 'nd', 'rd'];
		const index = page % 10;
		return endings[index < 4 ? index : 0];
	}
	const pageType = useMemo(() => {
		const targetPage = currentCampaign.pages[i];
		if(!targetPage) return {type: 'decoration', text: 'Decoration'};
		/* Return the first non-decorative element as the type of the page */
		let f;
		for(let j=0;j<targetPage.elements.length && !f;j++){
			const t = elementTypeMap.get(targetPage.elements[j].type);
			if(t && ((!t.isDecorative && t.type!=="submitButton")||(t.type==="quizResults"))){
				f = {"type": t.type, "text": t.text};
			}
		}
		if(!f) f = {type: 'decoration', text: 'Decoration'};
		return f;
	}, [i, currentCampaign])

	const isDraggable = useMemo(() => {
		const campaignType = campaignTypeMap.get(currentCampaign?.type);
		if(!campaignType) return true;

		return !campaignType.nonDraggablePages.includes(pageType?.type) && (i !== currentCampaign.pages.length-1);
	}, [i, currentCampaign, pageType]);
	
	const isDeletable = useMemo(() => {
		const campaignType = campaignTypeMap.get(currentCampaign?.type);
		if(!campaignType) return true;

		return !campaignType.nonDeletablePages.includes(pageType?.type) && (i !== currentCampaign.pages.length-1);
	}, [i, currentCampaign, pageType]);

	const DragHandle = SortableHandle(() => <span className="material-icons font--22 item pointer">drag_indicator</span>);

	return (
		<li onClick={ () => goTo(page, 'normal') }>
			<div className="flex flex--row">
				<div className={getPageCss(page.id, i)}>
					<span className="campaign__edit-pageNumber">{i+1}{getPageEnding(i+1)}</span>
					{ i  < currentCampaign.pages.length-1 && 
						<div className="campaign__edit-pageText">
							Page
							<div className="text--muted font--12">{pageType.text}</div>
						</div>
					}
					{ i  === currentCampaign.pages.length-1 &&
						<>
							<div className="campaign__edit-pageText text--warning">
								End
							</div>
							<div className="text--muted font--10">
								This is the last page of the campaign. Say thanks to your participants.
							</div>
						</>
					}
					<div className="campaign__edit-pageActions">
						{ i  !== currentCampaign.pages.length-1 &&
							<div>
								<Dropdown>
									<div className="btn btn-sm dropdown-trigger">
										<div className="material-icons pointer font--20">
											tune
										</div>
									</div>
									<div className="dropdown__menu">
										<div className="dropdown__content">
											<div className="dropdown__item page__dropdown-action" onClick={() => duplicatePage(page)}>
												<span className="material-icons vm--align mright--5">
													content_copy
												</span>
												<span className="font--12">Duplicate Page</span>
											</div>
											{ isDeletable &&
												<div className="dropdown__item page__dropdown-action" onClick={() => setDeleteModal(page)}>
													<span className="material-icons vm--align mright--5 text--danger">
														clear
													</span>
													<span className="font--12">Delete Page</span>
												</div>
											}
										</div>
									</div>
								</Dropdown>
							</div>
						}
						{ isDraggable &&
							<div className="btn btn-sm float--right">
								<DragHandle/>
							</div>
						}
					</div>
				</div>

			</div>
		</li>
	)
}

export default Page