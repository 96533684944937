import { useMemo } from "react";
import EditableInput from "../../../../utils/EditableInput";
import WheelOfPrizesPrizeOptions from "./WheelOfPrizesPrizeOptions";
import ImageUpload from '../../../../utils/ImageUpload';
import ColorPallete from '../../../../utils/ColorPallete';
import { getDefaultElement } from "../../CampaignAddDefaults";
import { SortableHandle } from "react-sortable-hoc";

function WheelOfPrizesSlice({index, slice, elementCopy, currentCampaignId, _updateSubmit, deletePrize, updatePrize, locked}) {
	console.log(index, slice, currentCampaignId, _updateSubmit)
	const DragHandle = SortableHandle(() => <span className="material-icons font--25 drag__handle pointer">drag_indicator</span>);
	const updateSlice = (key, value, extra) => {
		const sliceIdx = extra?.sliceIdx;
		if(sliceIdx === undefined) return;
		if(key==='imageSizeMultiplier') value = parseFloat(value);
		elementCopy['slices'][sliceIdx][key] = value
		console.log(value);
		_updateSubmit();
	}
	const deleteSlice = () => {
		elementCopy['slices'] = elementCopy['slices'].filter((currentSlice) => slice.id !== currentSlice.id)
		_updateSubmit();
	}
	const addPrizeToSlice = sliceIdx => {
		const d = { text: 'New Prize', stock:  1 }
		elementCopy['slices'][sliceIdx]['prizes'].push( getDefaultElement('wheelPrize', d) )
		_updateSubmit();
	}
	const isLastSlice = useMemo(() => {
		return index === elementCopy.slices.length-1;
	}, [index, elementCopy])

	return (
		<div className="wheel__slice" key={`slice__${index}`} >
			<div className="wheel__slice-image">
				<ImageUpload dimensions="420x75px" campaign_id={currentCampaignId} extra={{sliceIdx: index}} file={slice.image} item="image" updateAction={updateSlice} />
			</div>
			{(elementCopy.slices.length > 2 && !isLastSlice) && <DragHandle/>}
			<div className="wheel__slice-content">
				<div className="wheel__slice-remove" onClick={deleteSlice} disabled={elementCopy.slices.length < 3 || locked}>
					<span className="material-icons">
						clear
					</span>
				</div>
				<div className="flex flex--row flex--wrap">
					<div className="wheel__slice-contentSection">
						<div className="wheel__slice-icon">
							{ slice.prizes.length > 0 ?
								<div className="btn btn-warning-pastel btn-sm">
									<span className="material-icons font--15">
										emoji_events
									</span>
								</div>
								:
								<div className="btn btn-secondary-pastel btn-sm">
									<span className="material-icons font--15">
										flaky
									</span>
								</div>
							}
						</div>
						<div className="wheel__slice-contentText">
							{<EditableInput item="text" extra={{ sliceIdx: index }} type="text" startValue={slice.text} updateAction={updateSlice} placeholder={'Type here...'} />}
						</div>
						{slice.image && slice.image!=='/images/common/placeholder.png' && <div className="wheel__slice-contentText">
							{<EditableInput item="imageSizeMultiplier" extra={{ sliceIdx: index }} type="range" startValue={slice.imageSizeMultiplier} updateAction={updateSlice} placeholder={'Image size'} min={0.8} max={3} step={0.1} />}
						</div>}
						<div className="wheel__slice-contentColors">
							<div className="flex flex--row justify--center justify--spread">
								<label className="font--11 mright--5 mtop--10">Background</label>
								<ColorPallete dropdown={false} extra={{ sliceIdx: index }} item="color" value={slice.color} type={'rgba'} updateAction={updateSlice} />
							</div>
							<div className="flex flex--row justify--center justify--spread mleft--20">
								<label className="font--11 mright--5 mtop--10">Text</label>
								<ColorPallete dropdown={false} extra={{ sliceIdx: index }} item="textColor" value={slice.textColor} type={'hex'} updateAction={updateSlice} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{ !isLastSlice &&
				<div className="wheel__slice-contentPrizes">
					<div className="flex flex--row">
						<h4 className="text--muted">Prizes</h4>
						<div className="btn btn-primary-soft text--normal btn-md float--right" onClick={() => addPrizeToSlice(index)} disabled={locked}>
							<span className="material-icons font--20">add</span>
							Add
						</div>
					</div>
					<div className="wheel__slice-contentPrizesWrap">
						{slice.prizes.map((prize, idx) => {
							return (
								<WheelOfPrizesPrizeOptions
									key={prize.id}
									idx={idx}
									prize={prize}
									sliceIdx={index}
									update={updatePrize}
									_updateSubmit={_updateSubmit}
									deletePrize={deletePrize}
									currentCampaignId={currentCampaignId}
									advancedOnModal={true}
									locked={locked}
								/>
							)
						})}
					</div>
				</div>
			}
			{ isLastSlice &&
				<div className="card card__warning text--center font--12">
					<span className="material-icons font--30">warning</span><br />
					The last slice is always reserved as a non-winning slice so we can show the participant
					that he lost if he doesn't get any prizes.
				</div>
			}
		</div>
	)
}

export default WheelOfPrizesSlice