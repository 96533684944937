import { useState, useEffect } from "react";

const cyrrentYear = (new Date()).getFullYear();
const days = [];
const months = [];
const years = [];
for(let i=1;i<=31;i++) days.push({value:(i<10)?('0'+i):i, text:(i<10)?('0'+i):i});
for(let i=1;i<=12;i++) months.push({value:(i<10)?('0'+i):i, text:(i<10)?('0'+i):i});
for(let i=cyrrentYear;i>=cyrrentYear-120;i--) years.push({value:i, text:i});
const defaultDayPlaceholder = "Day";
const defaultMonthPlaceholder = "Month";
const defaultYearPlaceholder = "Year";

function FormFieldDate({ data, value, sending, elementErrors, onChange }) {
	const [err, setErr] = useState(false);
	if (value === undefined) value = false;
	const required = data.required;
	const [day, setDay] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	let ageOK = true;
	if(data.ageRestriction){
		const minDate = new Date();
		const date = new Date(value);
		minDate.setMonth(minDate.getMonth()-(12*data.ageRestriction));
		ageOK = minDate > new Date(date);
	}

	const getInputStyling = () => {
		let className = "FormFieldDate md__input mtop--15";
		if (err || !ageOK) className += ' input--error';
		return className;
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])
	useEffect(() => {
		let e = null;
		if((day==='')&&(month==='')&&(year==='')){
			e='';
			setErr(false);
		}
		else if((day!=='')&&(month!=='')&&(year!=='')){
			let y = parseInt(year);
			let m = parseInt(month)-1;
			let d = parseInt(day);
			const a = new Date();
			a.setFullYear(y, m, d);
			a.setHours(12, 0, 0, 0);
			e = `${year}-${month}-${day}`;
			setErr((y!==a.getFullYear()) || (m!==a.getMonth()) || (d!==a.getDate()));
		}
		else {
			setErr(true);
		}
		onChange(data.id, e);
	}, [day, month, year])

	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
			<div className="mtop--5">
				<select value={day} onChange={(e)=>setDay(e.target.value)}>
					<option value="">{data.dayPlaceholder||defaultDayPlaceholder}</option>
					{days.map(({value, text})=><option value={value}>{text}</option>)}
				</select>
				<span style={{verticalAlign:"middle", padding:"5px", fontSize:"1.5em"}}>/</span>
				<select value={month} onChange={(e)=>setMonth(e.target.value)}>
					<option value="">{data.monthPlaceholder||defaultMonthPlaceholder}</option>
					{months.map(({value, text})=><option value={value}>{text}</option>)}
				</select>
				<span style={{verticalAlign:"middle", padding:"5px", fontSize:"1.5em"}}>/</span>
				<select value={year} onChange={(e)=>setYear(e.target.value)}>
					<option value="">{data.yearPlaceholder||defaultYearPlaceholder}</option>
					{years.map(({value, text})=><option value={value}>{text}</option>)}
				</select>
			</div>
			{elementErrors?.errors.map((error) => <div className="text--danger mtop--10">{error.message}</div>)}
			{!elementErrors?.errors?.length && !ageOK && !!value && <div className="mtop--10" style={{color:data.ageRestrictionTextColor||'#D23369'}}>{data.ageRestrictionText}</div>}
		</div>
	);
};
export default FormFieldDate;
