import { useState, useEffect } from "react";
import Select from 'react-select';

const colourStyles = {
	control: (styles) => ({ 
		...styles, 
		backgroundColor: 'var(--app-secondary)',
		boxShadow: 'none', //'0 0 0 1px var(--app-primary)',
		':hover': {
			...styles[':hover'],
			borderColor: 'var(--app-primary)'
		},
		':focus': {
			...styles[':focus'],
			borderColor: 'var(--app-primary)'
		}
	}),
	input: (styles) => ({ ...styles }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isSelected ? 'var(--app-primary)' : 'RGBA(var(--app-primary-rgb) / 20%)',
			backgroundColor: isDisabled
				? undefined
				: isSelected
					? 'var(--app-primary)'
					: isFocused
						? 'RGBA(var(--app-primary-rgb) / 20%)'
						: undefined,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? 'var(--app-primary)'
						: 'RGBA(var(--app-primary-rgb) / 20%)'
					: undefined,
			},
		};
	},
};

function FormFieldChoiceOptions({ data, value, sending, elementErrors, onChange }) {
	const [err, setErr] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	if (value === undefined) value = [];
	const required = data.required;

	function validateAndSave(option) {
		if (isChecked(option)) {
			removeOption(option)
		} else {
			addOption(option)
		}
	}
	function addOption(option) {
		let tmp = value
		if (data.max > 1) {
			const f = tmp.find(o => o == option)
			if (f) return
			tmp.push(option)
		} else {
			if (selectOpen) setSelectOpen(false);
			tmp = [option]
		}
		setData(tmp)
	}
	function removeOption(option) {
		let tmp = value.filter(o => o != option)
		setData(tmp)
	}
	function setData(d) {
		console.log(d);
		setErr(false);
		console.log('* FormFieldChoiceOptions sends:', data.id, d)
		onChange(data.id, d);
	}
	const getInputStyling = () => {
		let className = "FormFieldChoiceOptions md__input mtop--15";
		if (err) className += ' input--error';
		return className;
	}
	const isChecked = (option) => {
		return value.find((v) => v == option)
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])

	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
			{data.appearance == 'radio' ?
				<div className="mtop--20">
					{data.options.map((option, idx) => {
						return (
							<div key={`registration__option${idx}`} className={`registration__choice ${isChecked(option) ? "registration__ch-checked" : ""}`} onClick={() => validateAndSave(option)} disabled={sending}>
								{isChecked(option) ?
									<span className="material-icons font--25 registration__choice-checked">radio_button_checked</span>
									:
									<span className="material-icons font--25 registration__choice-unchecked">radio_button_unchecked</span>
								}
								<span className="vm--align mleft--10 mtop--5">
									{option}
								</span>
							</div>
						)
					})}
				</div>
				:
				<div className="mtop--20 registration__select-wrapper">
					{data.customId === 'country' &&
						<Select
							options={data.options.map((option, idx) => ({ value: option, label: option }))}
							styles={colourStyles}
							isDisabled={sending}
							isLoading={sending}
							onChange={(choice) => validateAndSave(choice.value)}
						/>
					}
					{data.customId !== 'country' &&
						<>
							<div className="registration__select-trigger" onClick={() => setSelectOpen(!selectOpen)}>
								<div className="registration__select-triggerOptions">
									{value.length == 0 ? 'Choose...' : ''}
									{value.map((v, idx) => {
										return <span key={`select${idx}`}>{idx != 0 && ', '}{v}</span>
									})}
								</div>
								<span className="material-icons float--right font--30 pointer">{selectOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
							</div>
							{selectOpen ?
								<div className="registration__select-inner">
									<div className="registration__select-header">
										<span className="material-icons text--danger mtop--10 pointer float--right font--30" onClick={() => setSelectOpen(!selectOpen)}>
											clear
										</span>
									</div>
									{data.options.map((option, idx) => {
										return (
											<div key={`registration__option${idx}`} className={`registration__choice ${isChecked(option) ? "registration__ch-checked" : ""}`} onClick={() => validateAndSave(option)} disabled={sending}>
												{isChecked(option) ?
													<span className="material-icons font--25 registration__choice-checked">radio_button_checked</span>
													:
													<span className="material-icons font--25 registration__choice-unchecked">radio_button_unchecked</span>
												}
												<span className="vm--align mleft--10 mtop--5">
													{option}
												</span>
											</div>
										)
									})}
								</div> : ''
							}
						</>
					}
				</div>
			}
			{elementErrors?.errors.map((error) => <div className="text--danger mtop--10">{error.message}</div>)}
		</div>
	);
};
export default FormFieldChoiceOptions;
