import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";

function QuizResultsOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const { marginTop, background, html, showCorrectQuestionCount, preHtml, postHtml, showCorrectAnswers } = elementCopy;
	const update = (key, value) => {
		if(key === 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}

	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Question</label>
				<RichText item={'html'} value={html} updateAction={update} />
			</div>
			<div className="campaign__option flex flex--row mtop--15">
				<EditableInput item="showCorrectQuestionCount" type="checkbox" isSwitch startValue={showCorrectQuestionCount} updateAction={update} />
				<label className="font--13 text--bold mleft--10">Show number of correct answers</label>
			</div>
			{showCorrectQuestionCount && <>
				<div className="campaign__option mtop--15">
					<label className="text--bold">Text before correct answer count</label>
					<RichText item={'preHtml'} value={preHtml} updateAction={update} isSmall={true}/>
				</div>
				<div className="campaign__option mtop--15">
					<label className="text--bold">Text after correct answer count</label>
					<RichText item={'postHtml'} value={postHtml} updateAction={update} isSmall={true}/>
				</div>
			</>}
			<div className="campaign__option flex flex--row mtop--15">
				<EditableInput item="showCorrectAnswers" type="checkbox" isSwitch startValue={showCorrectAnswers} updateAction={update} />
				<label className="font--13 text--bold mleft--10">Show correct answers</label>
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default QuizResultsOptions;
