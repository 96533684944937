import { useState, useEffect } from "react";
function FormFieldInput({ data, value, sending, elementErrors, onChange, autofill=false }) {
	const [err, setErr] = useState(false);
	if (value === undefined) value = autofill ? getLocalStoredValueByType() : '';
	const required = data.required;

	console.log('~FormFieldInput', data)
	function setData(targetValue) {
		setErr(false);
		console.log('* FormFieldInput sends:', data.id, targetValue)
		onChange(data.id, targetValue);
		setLocalStoredValueByType(targetValue)
	}

	const inputType = ['firstName', 'lastName'].includes(data.type) ? 'text' : data.type;

	const getInputStyling = () => {
		let className = "FormFieldInput mtop--15";
		if (err) className += ' input--error';
		return className;
	}
	function getLocalStoredValueByType() {
		let o = null
		switch (data.type) {
			case 'firstName':
				o = localStorage.getItem('sportovivo__firstName')
				break;
			case 'lastName':
				o = localStorage.getItem('sportovivo__lastName')
				break;
			case 'email':
				o = localStorage.getItem('sportovivo__email')
				break;
			default:
				break;
		}
		if (o) onChange(data.id, o)
		return o ? o : ''
	}
	function setLocalStoredValueByType(targetValue) {
		switch (data.type) {
			case 'firstName':
				localStorage.setItem('sportovivo__firstName', targetValue)
				break;
			case 'lastName':
				localStorage.setItem('sportovivo__lastName', targetValue)
				break;
			case 'email':
				localStorage.setItem('sportovivo__email', targetValue)
				break;
			default:
				break;
		}
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])
	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<input onChange={(e) => setData(e.target.value)} value={value} disabled={sending} placeholder={data.placeholder} type={inputType} />
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default FormFieldInput;
