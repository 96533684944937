import { useState, useEffect } from "react";
function FormFieldNumeric({ data, value, sending, elementErrors, onChange, autofill = false }) {
	const [err, setErr] = useState(false);
	if (value === undefined) value = '';
	const required = data.required;

	console.log('~FormFieldNumeric', data)
	function setData(targetValue) {
		setErr(false);
		console.log('* FormFieldNumeric sends:', data.id, targetValue)
		onChange(data.id, targetValue);
	}
	const getInputStyling = () => {
		let className = "FormFieldNumeric mtop--15";
		if (err) className += ' input--error';
		return className;
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])
	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<div className="text--muted">Allowed lengths: {data.allowedLengths.map((allowed, idx) => `${idx !== 0 ? ' | ' : ''}${allowed}`)} characters</div>
			<input onChange={(e) => setData(e.target.value)} value={value} disabled={sending} placeholder={data.placeholder} type="text" />
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default FormFieldNumeric;
