import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, connectAuthEmulator, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithCustomToken, sendEmailVerification, sendPasswordResetEmail, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator, getMultiFactorResolver } from "firebase/auth";
import { getFirestore, doc, getDoc, getDocs, collection, query, orderBy, limit, startAfter, connectFirestoreEmulator, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, ref, uploadBytesResumable, connectStorageEmulator, getDownloadURL } from "firebase/storage";

const provider = new GoogleAuthProvider();
const firebaseConfig = {
	apiKey: "AIzaSyBD_NtznWHLPV4JiSVbPLz8k6LBfiCNG90",
	authDomain: "morepolls-1cc1e.firebaseapp.com",
	projectId: "morepolls-1cc1e",
	storageBucket: "morepolls-1cc1e.appspot.com",
	messagingSenderId: "817940652447",
	appId: "1:817940652447:web:23eeb35580259554a2ddaa"
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
if(process.env.NODE_ENV === 'development') connectAuthEmulator(auth, "http://localhost:28754");

const db = getFirestore(firebaseApp);
if(process.env.NODE_ENV === 'development') connectFirestoreEmulator(db, "localhost", 29479);

const functions = getFunctions(firebaseApp, "europe-west1");
if(process.env.NODE_ENV === 'development') connectFunctionsEmulator(functions, 'localhost', 28764);

const storage = getStorage();
if(process.env.NODE_ENV === 'development') connectStorageEmulator(storage, 'localhost', 9199);

function showLogin() {
	signInWithPopup(auth, provider)
	.then((result) => {
		// console.log('result', result)
		// This gives you a Google Access Token. You can use it to access the Google API.
		// const credential = GoogleAuthProvider.credentialFromResult(result);
		// const token = credential.accessToken;
		// The signed-in user info.
		// const user = result.user;
	})
	.catch((error) => {
		console.log('login error', error, { ...error })
		if (error.code === "auth/multi-factor-auth-required") {
			const resolver = getMultiFactorResolver(auth, error);
			cbs["two-factor"].forEach((cb) => cb(resolver));
		}
		// console.log('error', error, {...error})
		// Handle Errors here.
		// const errorCode = error.code;
		// const errorMessage = error.message;
		// The email of the user's account used.
		// const email = error.customData.email;
		// The AuthCredential type that was used.
		// const credential = GoogleAuthProvider.credentialFromError(error);
	});
}
function loginWithEmailAndPassword(email, password) {
	return signInWithEmailAndPassword(auth, email, password);
}
function loginWithCustomToken(token) {
	return signInWithCustomToken(auth, token);
}
/**
 * @param {string} email The new user's email.
 * @param {string} password The new user's password.
 * @param {object} [request] Optionally an object containing
 * @param {string} request.id The request's id
 * @param {string} request.secret The request's secret
 * @throws {error} error.details can be "IncorrectSecret", "RequestNotPending", "EmailExists", "InvalidPassword" on undefined
 */
async function registerWithEmailAndPassword(email, password, request) {
	if (request) {
		const {id, secret} = request;
		const dt = await httpsCallable(functions, 'app/createAccount')({email, password, "request": {id, secret}});

		return dt.data;
	}else{
		return createUserWithEmailAndPassword(auth, email, password);
	}
}

async function sendResetPasswordEmail(email) {
	const {data} = await httpsCallable(functions, 'app/checkEmailHasPassword')({email});
	if (!data.emailFound || data.hasPasswordLogin) {
		await sendPasswordResetEmail(auth, email);
	} else {
		throw new Error("Email has no password");
	}
}

function logout() {
	auth.signOut();
}

const cbs = { "login": [], "logout": [], "two-factor": [] };
function on(action, cb) {
	cbs[action].push(cb);
}
function off(action, cb) {
	const index = cbs[action].indexOf(cb);
	if(index>=0) cbs[action].splice(index, 1);
}

onAuthStateChanged(auth, (user) => {
	if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		// console.log('user', user);
		cbs["login"].forEach((cb)=>cb(user));
	} else {
		// console.log('User is signed out');
		cbs["logout"].forEach((cb)=>cb());
	}
});

async function getUser(userId){
	// console.log('userId', userId)
	if(!userId) return undefined;
	const userRef = doc(db, "users", userId);
	const userDoc = await getDoc(userRef);
	if (userDoc.exists()) {
		const settings = userDoc.data();
		return {settings};
	}
	else {
		return undefined;
	}
}
async function getCampaigns(userId, count, getAfter){
	if(!userId) return undefined;
	const campaignsRef = collection(db, "users", userId, "campaigns");
	const batch = getAfter?await query(campaignsRef, orderBy("timestamp", "desc"), limit(count), startAfter(getAfter)):await query(campaignsRef, orderBy("timestamp", "desc"), limit(count));
	const out = [];
	const docs = await getDocs(batch);
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data(), "doc": doc});
	});
	return out;
}
async function getOrganizationCampaigns(organizationId, count, getAfter){
	if(!organizationId) return undefined;
	await gainAccessToOrganizationIfNeeded(organizationId);
	const campaignsRef = collection(db, "organizations", organizationId, "campaigns");
	const batch = getAfter?await query(campaignsRef, orderBy("timestamp", "desc"), limit(count), startAfter(getAfter)):await query(campaignsRef, orderBy("timestamp", "desc"), limit(count));
	const out = [];
	const docs = await getDocs(batch);
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data(), "doc": doc});
	});
	return out;
}
async function getCampaign(campaignId){
	if(!campaignId) return undefined;
	await gainAccessIfNeeded(campaignId);
	const campaignPublicRef = doc(db, "campaigns", campaignId);
	const campaignPrivateRef = doc(db, "campaignsData", campaignId);

	const [publicDoc, privateDoc] = await Promise.all([getDoc(campaignPublicRef), getDoc(campaignPrivateRef)])

	if (publicDoc.exists() && privateDoc.exists()) {
		return {campaign:publicDoc.data(), campaignMeta:privateDoc.data()};
	}
	else {
		return undefined;
	}
}
async function getCampaignData(campaignId){
	// console.log('campaignId', campaignId)
	if(!campaignId) return undefined;
	const campaignRef = doc(db, "campaignsData", campaignId);
	const campaignDoc = await getDoc(campaignRef);

	if (campaignDoc.exists()) {
		const campaign = campaignDoc.data();
		return {campaign};
	}
	else {
		return undefined;
	}
}

async function storeUser(userId) {
	const dt = await httpsCallable(functions, `app/create/users/${userId}`)();
	return dt.data;
}

async function createCampaign(campaignData, campaignMeta, organizationId) {
	const o = {campaignData, campaignMeta};
	if (organizationId!==undefined) {
		o.organizationId = organizationId;
	}
	const dt = await httpsCallable(functions, 'app/create/campaigns')(o);
	return dt.data;
}
async function deleteCampaign(campaignId, force) {
	force = !!force;
	const dt = await httpsCallable(functions, `app/delete/campaigns/${campaignId}`)({force});
	return dt.data;
}
async function modifyCampaign(campaignId, changes) {
	const dt = await httpsCallable(functions, `app/modify/campaigns/${campaignId}`)({changes});
	return dt.data;
}

async function modifyCampaignData(campaignId, changes) {
	const dt = await httpsCallable(functions, `app/modify/campaignsData/${campaignId}`)({changes});
	return dt.data;
}

async function deleteResponses(campaignId) {
	const dt = await httpsCallable(functions, `app/delete/campaigns/${campaignId}/responses`)();
	return dt.data;
}

async function addCampaignPage(campaignId, pageType, pageId, position, page) {
	const dt = await httpsCallable(functions, `app/create/campaigns/${campaignId}/${pageType}/${pageId}`)({position, page});
	return dt.data;
}
async function deleteCampaignPage(campaignId, pageType, pageId) {
	const dt = await httpsCallable(functions, `app/delete/campaigns/${campaignId}/${pageType}/${pageId}`)();
	return dt.data;
}
async function moveCampaignPage(campaignId, pageType, pageId, newPosition) {
	const dt = await httpsCallable(functions, `app/move/campaigns/${campaignId}/${pageType}/${pageId}`)({position:newPosition});
	return dt.data;
}
async function replaceCampaignPage(campaignId, pageType, pageId, changes) {
	const dt = await httpsCallable(functions, `app/replace/campaigns/${campaignId}/${pageType}/${pageId}`)({changes});
	return dt.data;
}

async function createPageElement(campaignId, pageType, pageId, elementId, position, element) {
	const dt = await httpsCallable(functions, `app/create/campaigns/${campaignId}/${pageType}/${pageId}/elements/${elementId}`)({position, element});
	return dt.data;
}
async function deletePageElement(campaignId, pageType, pageId, elementId) {
	const dt = await httpsCallable(functions, `app/delete/campaigns/${campaignId}/${pageType}/${pageId}/elements/${elementId}`)();
	return dt.data;
}
async function movePageElement(campaignId, pageType, pageId, elementId, newPosition) {
	const dt = await httpsCallable(functions, `app/move/campaigns/${campaignId}/${pageType}/${pageId}/elements/${elementId}`)({position:newPosition});
	return dt.data;
}
async function replacePageElement(campaignId, pageType, pageId, elementId, changes) {
	const dt = await httpsCallable(functions, `app/replace/campaigns/${campaignId}/${pageType}/${pageId}/elements/${elementId}`)({changes});
	return dt.data;
}

async function addOrganization(name) {
	const dt = await httpsCallable(functions, `app/create/organizations`)({name});
	return dt.data;
}
async function modifyOrganization(organizationId, changes) {
	const dt = await httpsCallable(functions, `app/modify/organizations/${organizationId}`)(changes);
	return dt.data;
}
async function deleteOrganization(organizationId) {
	const dt = await httpsCallable(functions, `app/delete/organizations/${organizationId}`)();
	return dt.data;
}
async function getOrganizations(userId){
	if(!userId) return undefined;
	const organizationsRef = collection(db, "users", userId, "organizations");
	const docs = await getDocs(organizationsRef);
	const out = [];
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data()});
	});
	return out;
}
async function getOrganization(organizationId){
	if(!organizationId) return undefined;
	await gainAccessToOrganizationIfNeeded(organizationId);
	const orgRef = doc(db, "organizations", organizationId);

	const orgDoc = await getDoc(orgRef);
	if (orgDoc.exists()) {
		return orgDoc.data();
	}
	else {
		return undefined;
	}
}

async function removeUserFromOrganization(toRemoveId, organizationId) {
	const dt = await httpsCallable(functions, `app/removeUserFromOrganization`)({toRemoveId, organizationId});
	return dt.data;
}

async function uploadImage(campaignId, imageId, file, onStateChange, onSuccess, onFail){
	try{
		await gainAccessIfNeeded(campaignId);
	}catch(error){
		onFail(error);
	}
	const storageRef = ref(storage, `public/${campaignId}/images/${imageId}`);

	// https://firebase.google.com/docs/reference/js/v8/firebase.storage.UploadTask
	const uploadTask = uploadBytesResumable(storageRef, file);
	// console.log('uploadTask', uploadTask, uploadTask.then, uploadTask.catch)
	uploadTask.on('state_changed', onStateChange, onFail, ()=>{
		getDownloadURL(storageRef).then(onSuccess);
	});
}
async function gainElevatedCampaignAccess(campaignId) {
	const dt = await httpsCallable(functions, `app/gainElevatedCampaignAccess`)({campaignId});
	return dt.data;
}
async function gainAccessIfNeeded(campaignId) {
	const res = await auth.currentUser.getIdTokenResult();
	const hasAccess = res?.claims?.cs?.includes(campaignId)
	if(!hasAccess){
		const r = await gainElevatedCampaignAccess(campaignId);
		if(r.needsTokenRefresh){
			await auth.currentUser.getIdToken(true);
		}
	}
}

async function gainOrganizationAccess(organizationId) {
	const dt = await httpsCallable(functions, `app/gainOrganizationAccess`)({organizationId});
	return dt.data;
}
async function gainAccessToOrganizationIfNeeded(organizationId) {
	const res = await auth.currentUser.getIdTokenResult();
	const hasAccess = res?.claims?.orgs?.[organizationId]>Date.now();
	if(!hasAccess){
		const r = await gainOrganizationAccess(organizationId);
		if(r.needsTokenRefresh){
			await auth.currentUser.getIdToken(true);
		}
	}
}

/**
 * Get responses and keep getting changes to responses
 * @param {string} campaignId The campaign's id
 * @param {function} onAdded A callback called when a response is added
 * @param {function} onModified A callback called when a response is modified
 * @param {function} onRemoved A callback called when a response is removed
 * @return {function} A function that must be called when you no longer want response updates
 * @throw {error} An error if the user could not get access to the database
 */
async function getResponsesLive(campaignId, onAdded, onModified, onRemoved) {
	await gainAccessIfNeeded(campaignId);
	const campaignsRef = collection(db, "campaigns", campaignId, "responses");
	const unsubscribe = onSnapshot(query(campaignsRef), (snapshot) => {
		snapshot.docChanges().forEach((change)=>{
			switch(change.type){
				case 'added':
					onAdded(change.doc.id, change.doc.data());
					break;
				case 'modified':
					onModified(change.doc.id, change.doc.data());
					break;
				case 'removed':
					onRemoved(change.doc.id);
					break;
				default:
					console.error('Unexpected status:', change.type)
			}
		})
	})
	return unsubscribe;
}
/**
 * Get incoming requests live
 * @param {string} userId The user's id. If not set it will try to get the current user's id
 * @param {function} onAdded A callback called when a request is added
 * @param {function} onModified A callback called when a request is modified
 * @param {function} onRemoved A callback called when a request is removed
 * @return {function} A function that must be called when you no longer want request updates
 * @throw {error} An error if the user could not get access to the database
 */
async function getIncomingRequestsLive(userId, onAdded, onModified, onRemoved) {
	if(!userId) userId = auth.currentUser.uid;
	const campaignsRef = collection(db, "users", userId, "incomingRequests");
	const unsubscribe = onSnapshot(query(campaignsRef), (snapshot) => {
		snapshot.docChanges().forEach((change)=>{
			switch(change.type){
				case 'added':
					onAdded(change.doc.id, change.doc.data());
					break;
				case 'modified':
					onModified(change.doc.id, change.doc.data());
					break;
				case 'removed':
					onRemoved(change.doc.id);
					break;
				default:
					console.error('Unexpected status:', change.type)
			}
		})
	})
	return unsubscribe;
}

async function getOrganizationRequests(organizationId){
	if(!organizationId) return undefined;
	await gainAccessToOrganizationIfNeeded(organizationId);
	const campaignsRef = collection(db, "organizations", organizationId, "requests");
	const out = [];
	const docs = await getDocs(campaignsRef);
	docs.forEach((doc) => {
		out.push({
			"id": doc.id,
			"data": doc.data(),
		});
	});
	return out;
}

async function addRequest(organizationId, granteeEmail, role) {
	const dt = await httpsCallable(functions, `app/create/requests`)({organizationId, granteeEmail, role});
	return dt.data;
}
async function acceptRequest(roleRequestId) {
	const dt = await httpsCallable(functions, `app/modify/requests/${roleRequestId}`)({"status":"accept"});
	return dt.data;
}
async function rejectRequest(roleRequestId) {
	const dt = await httpsCallable(functions, `app/modify/requests/${roleRequestId}`)({"status":"reject"});
	return dt.data;
}
async function cancelRequest(roleRequestId) {
	const dt = await httpsCallable(functions, `app/modify/requests/${roleRequestId}`)({"status":"cancel"});
	return dt.data;
}
async function getRequestUsingSecret(roleRequestId, secret) {
	const dt = await httpsCallable(functions, `app/get/requests/${roleRequestId}`)({secret});
	return dt.data;
}

async function getAWSCredentials(organizationId) {
	const dt = await httpsCallable(functions, `app/get/organizations/${organizationId}/awsAPI/current`)();
	return dt.data;
}
async function addAWSCredentials(organizationId, accessKeyId, secretAccessKey, isProduction) {
	const dt = await httpsCallable(functions, `app/replace/organizations/${organizationId}/awsAPI/current`)({accessKeyId, secretAccessKey, isProduction});
	return dt.data;
}
async function removeAWSCredentials(organizationId) {
	const dt = await httpsCallable(functions, `app/delete/organizations/${organizationId}/awsAPI/current`)();
	return dt.data;
}

async function changePostStatus(campaignId, userId, pageId, elementId, postId, newStatus) {
	const dt = await httpsCallable(functions, `app/modify/campaigns/${campaignId}/responses/${userId}/${pageId}/elements/${elementId}/${postId}/status`)(newStatus);
	return dt.data;
}

async function getProjectors(campaignId) {
	if (!campaignId) return undefined;
	const projectorRef = collection(db, "campaigns", campaignId, "projectors");
	const batch = await query(projectorRef, orderBy("timestamp", "desc"));
	const out = [];
	const docs = await getDocs(batch);
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data()});
	});
	return out;
}
async function replaceProjector(campaignId, projectorId, changes) {
	const dt = await httpsCallable(functions, `app/replace/campaigns/${campaignId}/projectors/${projectorId}`)({changes});
	return dt.data;
}

async function sendSMSLoginCode(resolver, recaptchaContainerId, cb) {
	const recaptchaVerifier = new RecaptchaVerifier(recaptchaContainerId, {
		"size": "invisible",
		"callback": function(response) {
			if(typeof cb === 'function') cb();
		},
	}, auth);
	const h = resolver.hints.find((h)=>h.factorId === PhoneMultiFactorGenerator.FACTOR_ID);
	if (!h) return undefined;
	const phoneInfoOptions = {
		multiFactorHint: h,
		session: resolver.session
	};
	const phoneAuthProvider = new PhoneAuthProvider(auth);
	return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
}
async function verifyPhoneCodeAndLogin(resolver, verificationId, verificationCode) {
	const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
	return resolver.resolveSignIn(multiFactorAssertion)
}

async function getPackages() {
	const dt = await httpsCallable(functions, `app/packages`)();
	return dt.data;
}

function getParsedToken() {
	const at = auth?.currentUser?.accessToken;
	if(at) {
		const str = base64UrlDecode(at.split('.')[1], "utf-8");
		return JSON.parse(str);
	}
	return null;
}

function base64UrlDecode(text, format) {
	const uint8Array = new Uint8Array(atob(text.replace(/-/g, "+").replace(/_/g, "/")).split("").map(char => char.charCodeAt(0)));
	return new TextDecoder(format).decode(uint8Array);
}

export {
	showLogin,
	logout,
	sendResetPasswordEmail,
	on,
	off,
	getUser,
	getCampaigns,
	getCampaign,
	getCampaignData,
	storeUser,
	createCampaign,
	deleteCampaign,
	modifyCampaign,

	modifyCampaignData,

	deleteResponses,

	addCampaignPage,
	deleteCampaignPage,
	moveCampaignPage,
	replaceCampaignPage,

	createPageElement,
	deletePageElement,
	movePageElement,
	replacePageElement,

	uploadImage,

	getResponsesLive,

	getOrganizationRequests,

	getPackages,

	loginWithEmailAndPassword,
	loginWithCustomToken,
	registerWithEmailAndPassword,

	addOrganization,
	modifyOrganization,
	deleteOrganization,
	getOrganizations,
	getOrganization,
	getOrganizationCampaigns,

	addRequest,
	acceptRequest,
	rejectRequest,
	cancelRequest,
	getRequestUsingSecret,

	getAWSCredentials,
	addAWSCredentials,
	removeAWSCredentials,

	changePostStatus,

	getProjectors,
	replaceProjector,

	getIncomingRequestsLive,

	removeUserFromOrganization,
	sendEmailVerification,

	sendSMSLoginCode,
	verifyPhoneCodeAndLogin,

	getParsedToken,

	base64UrlDecode,
};
