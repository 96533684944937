import {useDispatch} from 'react-redux';
import {useState} from 'react';
import EditableInput from "../../../../utils/EditableInput";
import {getDefaultElement} from "../../CampaignAddDefaults";
import { updateElement } from '../../../../utils/UpdateFunctions';
import { editElement } from '../../../../../actions/UserActions';
import PrizeSimulationModal from '../../../../utils/PrizeSimulationModal';
import ScratchPrize from './ScratchPrize';

function ScratchGeneral({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy, locked }) {
	const dispatch = useDispatch();
	const [simulationModal, setSimulationModal] = useState(false);
	const { prizes, participationProbability } = elementCopy;

	const _updateSubmit = () => {
		return updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const update = (key, value, extra) => {
		if(key === 'participationProbability') value = value * 10000;
		elementCopy[key] = value;
		_updateSubmit();
	}
	const addPrize = () => {
		const newPrize = getDefaultElement('scratchPrize');
		elementCopy['prizes'] = [...elementCopy['prizes'], ...[newPrize]];
		_updateSubmit();
	}
	const deletePrize = (prize) => {
		elementCopy.prizes = elementCopy.prizes.filter( p =>  p.id !== prize.id)
		_updateSubmit();
	}
	const updatePrize = (key, value, extra) => {
		return new Promise((resolve, reject) => {
			if(key === 'probability') value = value === null ? null : (value * 10000);
			const {prize_id} = extra
			elementCopy.prizes.forEach((prize) => {
				if(prize.id === prize_id){
					if ((key === 'stock') && (value === null)) {
						prize.stock = null;
						if (['spread', 'reserve'].includes(prize.strategy?.strategy)) prize.strategy = null;
					}else{
						prize[key] = value
					}
				}
			})
			resolve(true);
		})
	}

	return (
		<div className="pt-4">
			<button className="btn btn-primary mt-4" onClick={()=>setSimulationModal(true)}>Simulate Prize Distribution</button>
			{simulationModal && <PrizeSimulationModal currentElement={{...currentElement, participationProbability, prizes}} closeCb={setSimulationModal}/>}
			<div className="form__group">
				<div className="form__group-label">
					Participation Probability
					<div className="text--muted font--12">This is the probability of the user participating on the spin and winning a prize.</div>
				</div>
				<div className="form__group-content">
					<EditableInput item="participationProbability" type="number"  min="0" startValue={participationProbability ? participationProbability / 10000 : 0} updateAction={update} placeholder={'50'} />
					<div className="text--warning font--12">Min: 0%-Max: 100%</div>
				</div>
			</div>
			<div className="mtop--15 flex flex--row justify--spread">
				<button className="btn btn-primary" onClick={addPrize} disabled={locked}>
					<span className="material-icons">add</span>
					Add Prize
				</button>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Edit each prize</label>
				<div className="scratch-contentPrizes">
					<div className="scratch-contentPrizesWrap">
						{ prizes.map( (prize, idx) =>
							<ScratchPrize
								key={`prize_${idx}`}
								idx={idx}
								prize={prize}
								update={updatePrize}
								_updateSubmit={_updateSubmit}
								deletePrize={deletePrize}
								currentCampaignId={currentCampaignId}
								advancedOnModal={true}
								locked={locked}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ScratchGeneral;
