import { useState, useEffect } from "react";
import "./scss/FreeText.scss";
function FreeText({data, value, sending, elementErrors, onChange}) {
	const [err, setErr] = useState(false);
	console.log('~NumericPrediction', data.id, value, elementErrors)
	const setData = (e) => {
		setErr(false);
		const newValue = e.target.value;
		console.log('* FreeText sets and sends:', data.id, value, '->', newValue)
		onChange(data.id, newValue);
	};
	const getStyling = () => {
		let className = "FreeText";
		if(err)	className += ' input--error';
		return className;
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function(e){
			setErr(true);
		});
	}, [elementErrors])
	return (
		<div className={getStyling()} style={{marginTop:data?.marginTop}}>
			<textarea value={value} onChange={setData} disabled={sending} placehoilder={data.placeholder ? data.placeholder : 'Type here...'} style={{background: data.background && data.background, color: data.textColor && data.textColor}}/>
		</div>
	);
};
export default FreeText;
