import { useEffect, useState } from "react";
import "./scss/SlideShow.scss";
import Carousel from 'nuka-carousel';
function SlideShow({ data }) {
	const [mappedSlides, setMappedSlides] = useState([])
	const [currentCategoryTitle, setCurrentCategoryTitle] = useState('')
	const getStyling = () => {
		const w = mappedSlides.length < 3 ? (mappedSlides.length * 33) + '%' : '100%';
		return {
			margin: 'auto',
			marginTop: data?.marginTop,
			background: data?.background,
			color: data?.textColor,
			width: w,
			height: data?.height,
		};
	}
	const compVisibleSlides = () => {
		return data.slidesPerView > mappedSlides.length ? mappedSlides.length : data.slidesPerView;
	}
	const getSlideStyling = () => {
		return {
			maxWidth: '95%',
			maxHeight: data?.height
		}
	}
	const redirect = slide => {
		if (slide.url) {
			if (slide.target === 'newTab') {
				window.open(slide.url, "_blank")
			} else {
				window.open(slide.url, "_self")
			}
		}
	}
	const setCurrent = (idx) => {
		setCurrentCategoryTitle(mappedSlides[idx]['category'])
	}
	useEffect(() => {
		if(!data.categories) return;

		let o = []
		data.categories.forEach((category) => {
			category.slides.forEach((slide, idx) => {
				o.push({ ...slide, ...{ category: category.title ? category.title : '' } })
			})			
		})
		setMappedSlides(o);
	}, [data.categories])
	useEffect(() => {
		if (mappedSlides.length > 0) setCurrent(0);
	}, [mappedSlides])
	return (
		<div style={getStyling()}>
			<div className="text--center">
				<h4>{currentCategoryTitle}</h4>
			</div>
			<Carousel className="slideshow" animation='slide' afterSlide={setCurrent} speed={2000} autoplayInterval={5000} pauseOnHover={true} autoplay={mappedSlides.length > 3} withoutControls wrapAround slidesToScroll={compVisibleSlides()} slidesToShow={compVisibleSlides()}>
				{mappedSlides.map((slide, s) => {
					return (
						<div key={`slide_${s}`} className="slideshow__slide" onClick={() => redirect(slide)}>
							<img style={getSlideStyling()} src={slide.image} />
							{slide.label && <div className="slidehosw__title">{slide.label}</div>}
						</div>
					)
				})}
			</Carousel>
		</div>
	);
};
export default SlideShow;
