import "./scss/Link.scss";
function Link({data}) {
	const {text, marginTop, target, url, textAlign, background, textColor} = data;
	return (
		<div style={{marginTop: marginTop, textAlign: textAlign}}>
			{target === 'self' ? 
				<a className="btn btn-primary btn-primary-link btn-lg" href={url} style={{background: background, color: textColor}}>
					{text}
				</a>
			:
				<a className="btn btn-primary btn-primary-link btn-lg" target='_blank' rel="noreferrer" href={url} style={{background: background, color: textColor}}>
					{text}
				</a>
			}
		</div>
	);
};
export default Link;
