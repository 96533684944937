import { useState, useEffect } from 'react';
import "./scss/Poll.scss";
import Select from 'react-select';

function shouldShowCommentBox (selectedOptions, optionsMap) {
	return !!selectedOptions.find((o)=>optionsMap[o]?.allowComment);
}

function Poll({ data, value, sending, elementErrors, onChange, globalStyling, correctAnswer }) {
	const [calculatedOptions, setCalculatedOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([])
	console.log('~Poll', data, value, elementErrors)
	const optionsMap = {};
	data.options.forEach((o)=>{
		optionsMap[o.id] = o;
	});

	const showCommentBox = shouldShowCommentBox(value?.selected||[], optionsMap);

	if (!value) value = {"selected":[], "comment":""};
	else if (Array.isArray(value)) value = {"selected":value, "comment":""};
	else if (!Array.isArray(value.selected)) value.selected=[];

	const setData = (newValue) => {
		let dt = [...value.selected];
		const index = dt.indexOf(newValue);
		if (index < 0) {
			if (data.max === 1) {
				dt = [newValue];
			} else {
				dt.push(newValue)
			}
		} else {
			dt.splice(index, 1);
		}
		console.log('* Poll sets and sends:', data.id, value, '->', dt)
		onChange(data.id, {'selected': dt, comment: value.comment});
	};
	const setComment = (comment) => {
		onChange(data.id, {'selected': [...value.selected], comment: comment});
	};
	const getStyling = () => {
		return {
			marginTop: data?.marginTop,
			background: data?.background,
			color: data?.textColor,
			borderRadius: '4px',
			padding: '5px',
		}
	}
	const handleDropdownChange = (options) => {
		if(data.max === 1){
			setSelectedOptions([options])
			onChange(data.id, {'selected': [options.value], comment: value.comment})
			return
		}
		if (options.length > data.max) {
			setSelectedOptions(options.slice(0, options.length - 1))
			return false
		}
		const dt = options.map(option => option.value)
		onChange(data.id, {'selected': dt, comment: value.comment})
		setSelectedOptions(options)
	}
	useEffect(() => {
		setCalculatedOptions(data.options.map((option) => {
			return {
				value: option.id,
				label: option.text
			}
		}))
	}, [])
	return (
		<div style={getStyling()} className="poll poll__app">
			{data.question && <div className="poll__header" dangerouslySetInnerHTML={{ __html: data.question }} />}
			{data.layout === 'dropdown' &&
				<Select
					isMulti={data.max > 1}
					max={data.max}
					name="pollOptions"
					options={calculatedOptions}
					value={selectedOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={handleDropdownChange}
					styles={{
						control: (styles) => ({ ...styles, backgroundColor: 'white' }),
					}}
				/>
			}
			{data.layout === 'radio' &&
				<div className="mtop--10">
					{data?.options.map((option, index) => {
						const selected = (value?.selected||[]).includes(option.id);
						return (
							<div key={option.id} onClick={(e) => setData(option.id)} className="md__input poll__element poll__element-radio">
								<input checked={selected} type="radio" onChange={(e) => setData(option.id)} />
								<label>{option.text}</label>
							</div>
						)
					})}
				</div>
			}
			{data.layout === 'button' &&
				<div className="mtop--10 flex flex--column">
					{data?.options.map((option, index) => {
						const selected = (value?.selected||[]).includes(option.id);
						let extraClasses = selected?'poll__element-active':'';
						if(correctAnswer){
							if (option.id===correctAnswer) extraClasses += ' correct';
							else if (selected) extraClasses += ' incorrect';
						}
						return (
							<div key={option.id} className={"poll__element " + extraClasses} onClick={() => setData(option.id)}>
								<span className="material-icons mright--10 checked">task_alt</span>
								<span className="vm--align">{option.text}</span>
							</div>
						)
					})}
				</div>
			}
			{showCommentBox && <textarea placeholder="comment" maxlength="500" onChange={(e) => setComment(e.target.value)}></textarea>}
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default Poll;
