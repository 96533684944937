import "./scss/IFrame.scss";
import aspectImg from "./aspect-16-9.png";

function IFrame({data}) {
	const {marginTop, url, allow, allowfullscreen} = data;
	return (
		<div style={{marginTop: marginTop, position: 'relative'}}>
			<img src={aspectImg} style={{width: '100%', visibility: 'hidden'}} />
			<iframe 
				src={url} 
				{...(allow && { allow: allow }) || {} }
				{...(allowfullscreen && { allowFullScreen: true }) || {} }
				style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}
			>
			</iframe>
		</div>
	);
};
export default IFrame;
