import { useState, useEffect } from 'react';
function EditableInput(props) {
	const {type, item, startValue, updateAction, placeholder, extra, isSwitch, characterCount, withPencil, href, nonEmpty, min, max, step} = props;
	const [editMode, setEditMode] = useState(false);
	let s = startValue;
	if( (s===undefined) || (s===null) ){
		switch (type){
			case 'textarea':
			case 'text':
				s = '';
				break;
			case 'checkbox':
				s = false;
				break;
			case 'number':
				s = 0;
				break;
			case 'range':
				s = min;
				break;
			default:
				console.error('Unknown type')
		}
	}
	const [value, setValue] = useState(s);
	const update = (v) => {
		if( (type==='checkbox') || (type==='range') ){
			setValue(v);
			if(item){
				updateAction(item, v, extra);
			}else{
				updateAction(v);
			}
		}else{
			if(startValue !== value) updateAction(item, value, extra);
		}
	}
	const getLimitCss = () => {
		return value.length === characterCount ? 'text--danger' : '';
	}
	const isVisible = () => {
		return withPencil ? editMode : true;
	}
	useEffect(() => {
		if(nonEmpty && value.length === 0) return;
		if(max && (value > max)) setValue(max);
		if(min){
			if(!value || value < min)
				setValue(min);
		}
		if (value.length > characterCount) setValue(value.substring(0, characterCount));
	}, [value, nonEmpty, characterCount, min, max])
	useEffect(() => {
		setValue(s)
	}, [props, s])
	return (
		<>
			{withPencil &&
				<div className="editable__input-text">
					{!editMode &&
						<>
							{href && <a href={href}>{value}</a>}
							{!href && <span>{value}</span>}
							<span className="editable__input-edit material-icons font--20 pointer" onClick={() => setEditMode(true)}>
								edit
							</span>
						</>
					}
				</div>
			}
			{	isVisible() &&
				<>
					{ characterCount && <div className="character__count"> {value.length} / <span className={getLimitCss()}>{characterCount}</span></div>}
					<div className="flex flex--row editable__input-inp">
						{ type === 'text' && <input type="text" value={value} onChange={(e) => setValue(e.target.value)} onBlur={ () => update(value) } placeholder={placeholder ? placeholder : 'Type here...'} />}
						{ type === 'number' && <input type="number" value={value} onChange={(e) => setValue(parseInt(e.target.value))} onBlur={ () => update(value) } placeholder={placeholder ? placeholder : 'Type here...'} min={0} />}
						{ type === 'range' && (<div style={{flex:1}}>
							<div className="text--center">{placeholder}: {value}</div>
							<input type="range" value={value} onChange={(e) => update(e.target.value)} min={min} max={max} step={step} />
						</div>)}
						{ type === 'checkbox' && <input type="checkbox" checked={value} onChange={(e) => { update(e.target.checked)}} className={ isSwitch ? 'switch vm--align' : 'vm--align'} /> }
						{ type === 'textarea' && <textarea value={value} onChange={(e) => setValue(e.target.value)} onBlur={ () => update(value) } placeholder={placeholder ? placeholder : 'Type here...'} ></textarea> }
						{ editMode &&
							<span className="editable__input-edit material-icons font--25 mtop--10 vm--align text--success pointer" onClick={() => setEditMode(false) }>
								done
							</span>
						}
					</div>
					{ (nonEmpty && value.length === 0) && <div className="text--warning font--10">This item can't be empty</div> }
				</>
			}
		</>
	)
}

export default EditableInput
