import "./scss/NumericPrediction.scss";
import NumericPredictionOption from "./NumericPredictionOption";
function NumericPrediction({data, value, sending, elementErrors, onChange}) {
	console.log('~NumericPrediction', data.id, value, elementErrors)
	const setData = (id, newValue) => {
		console.log('* NumericPrediction sets and sends:', data.id, value, '->', {...value, [id]:newValue})
		onChange(data.id, {...value, [id]:newValue});
	};
	const getHeaderCss = () => {
		let o = {
			background: data.header.background ? data.header.background : '',
			color: data.header.color ? data.header.color : ''
		};
		return data.header.customCss ? {...o, ...data.header.customCss} : o;
	}
	const getOptionsCss = () => {
		let s = "numericPrediction__options";
		s += data.optionLayout == 'row' ? ' numericPrediction__optionsRow' : ' numericPrediction__optionsCol';
		return s;
	}
	const getOptionsStyling = () => {
		return {
			background: data.background ? data.background : ''
		};
	}
	const getOptionVsStyling = () => {
		if(!data.vs) return ;
		return {
			"color": data.vs.color && data.vs.color,
			"background": data.vs.background && data.vs.background,
		};
	}
	const getOptionSeparatorStyling = () => {
		if(!data.vs) return ;
		return {
			"background": data.divider.background && data.divider.background,
			"width": data.divider.width && data.divider.width,
		};
	}
	const viewSeparator = index => {
		return (index != 0) && (data.vs);
	}
	return (
		<div style={{marginTop:data?.marginTop}} className="numericPrediction">
			{data.header && <div className="numericPrediction-header" dangerouslySetInnerHTML={{__html: data.header.html}} style={getHeaderCss()}/>}
			<div className={getOptionsCss()} style={getOptionsStyling()}>
				{data.options.map((option, index)=>{
					return (
						<div className="numericPrediction__options-wrap" key={index}>
							{ data.vs?.visible && (index != 0) && <div className="numericPrediction__options-vs" style={getOptionVsStyling()}> {data.vs.text ? data.vs.text : 'VS'}</div>}
							{ viewSeparator(index) && <div className="numericPrediction__options-separator" style={getOptionSeparatorStyling()}></div> }
							<NumericPredictionOption data={option} showOptionsText={data.showOptionsText} textPosition={data.textPosition} min={data.min} max={data.max} value={value?.[option.id]} sending={sending} elementErrors={elementErrors} onChange={setData}/>
						</div>
					)
				})}
			</div>
		</div>
	);
};
export default NumericPrediction;
