import { useState, useEffect, useCallback } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import * as echarts from 'echarts';
import {spinWheel, getScratchPrize, calculatePrizeProbabilities, MAX_PROBABILITY} from './pickPrize.mjs';

class Test {
	constructor(name, settings) {
		this.name = name;
		this.settings = settings;
		this.spins = [];
	}
	getAllPrizes(taken) {
		const allPrizes = [];
		if(this.settings.type==="wheelOfPrizes"){
			this.settings.slices.forEach((slice, i)=>{
				if (slice.prizes.length) {
					slice.prizes.forEach((prize)=>{
						allPrizes.push({
							"prize": prize,
							"taken": taken?.[prize.id]||0,
							"slice": slice,
							"sliceIndex": i,
						});
					});
				}
			});
		}
		else if(this.settings.type==="scratch"){
			this.settings.prizes.forEach((prize)=>{
				allPrizes.push({
					"prize": prize,
					"taken": taken?.[prize.id]||0,
					"slice": null,
					"sliceIndex": null,
				});
			});
		}
		return allPrizes;
	}
	run(attendance) {
		const taken = {};
		const campaignId = "79288a0110aa4000bf9180068c294551";
		for(let i=0;i<attendance.length;i++){
			const [from, to, numberOfUsers] = attendance[i];
			if (numberOfUsers>=1){
				const chunk = (numberOfUsers<2)?0:((to-from)/(numberOfUsers-1));
				for (let i = 0; i < numberOfUsers; i++) {
					const t = Math.floor(from + (i * chunk));

					/* Extract all prizes */
					const allPrizes = this.getAllPrizes(taken);
					const pMod = this.settings.participationProbability/MAX_PROBABILITY;
					const preStats = calculatePrizeProbabilities(campaignId, allPrizes, t, InvalidStrategyError).map((p)=>{
						return {id:p.prize?.id, probability:p.probability*pMod, stockLeft:p.stockLeft, taken:p.taken};
					});

					let spin;
					if(this.settings.type==="wheelOfPrizes"){
						spin = spinWheel(campaignId, this.settings, taken, t, randomInteger, InvalidStrategyError);
					}else if(this.settings.type==="scratch"){
						spin = getScratchPrize(campaignId, this.settings, taken, t, randomInteger, InvalidStrategyError);
					}
					if (spin.won) {
						if (!taken[spin.prize.id]) taken[spin.prize.id] = 0;
						taken[spin.prize.id]++;
					}
					spin.t = t;
					spin.preStats = preStats;
					this.spins.push(spin);
				}
			}
		}

	}
	getResults() {
		const spins = this.spins;

		let participations=0, wins=0, losses=0;
		// count wins and losses
		spins.forEach((s)=>{
			if (s.won) {
				wins++;
			} else {
				losses++;
			}
			if (s.participated) participations++;
		});

		return {
			"participations": participations,
			"wins": wins,
			"losses": losses,
		};
	}
	getPercentagesGraphData() {
		const spins = this.spins;
		// Initialize data
		const map = new Map([
			['losses', new Map()],
			['wins', new Map()],
		]);
		this.getAllPrizes().forEach(({prize})=>{
			map.set(prize.id, new Map());
		});
		// Gather data
		spins.forEach((spin)=>{
			let wPercent = 0;
			spin.preStats.forEach(({id, probability})=>{
				wPercent+=probability;

				const p = map.get(id);
				if(!p.has(spin.t)) p.set(spin.t, [probability, 1]);
				else{
					const dt = p.get(spin.t);
					dt[0]+=probability;
					dt[1]++;
				}

			});
			const wdt = map.get('wins').get(spin.t);
			if(!wdt){
				map.get('wins').set(spin.t, [wPercent, 1]);
			}else{
				wdt[0]+=wPercent;
				wdt[1]++;
			}
			const ldt = map.get('losses').get(spin.t);
			if(!ldt){
				map.get('losses').set(spin.t, [MAX_PROBABILITY-wPercent, 1]);
			}else{
				ldt[0]+=MAX_PROBABILITY-wPercent;
				ldt[1]++;
			}
		});
		const pMax = (MAX_PROBABILITY/10000);
		// Order data and calculate averages
		return new Map(Array
			.from(map)
			.map(([id, dataMap])=>{
				const a = [id, Array.from(dataMap)];
				a[1]
				.sort((a,b)=>a[0]-b[0])
				.forEach(([t, [sum, c]], i)=>{
					// Keep only 2 decimals
					a[1][i][1] = Math.round((sum/c)/pMax)/100;
				});
				return a;
			})
		);
	}
	getStockLeftGraphData() {
		const spins = this.spins;
		// Initialize data
		const map = new Map([
			['total', new Map()],
		]);
		this.getAllPrizes().forEach(({prize})=>{
			if(prize.stock===null) return;
			map.set(prize.id, new Map());
		});
		// If none of the prizes have stock return an empty map
		if(map.size===1) return new Map();
		// Gather data
		spins.forEach((spin)=>{
			let totalStockLeft = 0;
			spin.preStats.forEach(({id, stockLeft})=>{
				if(stockLeft===Infinity) return;
				totalStockLeft+=stockLeft;

				const p = map.get(id);
				if(!p.has(spin.t)) p.set(spin.t, [stockLeft, 1]);
				else{
					const dt = p.get(spin.t);
					dt[0]+=stockLeft;
					dt[1]++;
				}

			});
			const wdt = map.get('total').get(spin.t);
			if(!wdt){
				map.get('total').set(spin.t, [totalStockLeft, 1]);
			}else{
				wdt[0]+=totalStockLeft;
				wdt[1]++;
			}
		});
		// Order data and calculate averages
		return new Map(Array
			.from(map)
			.map(([id, dataMap])=>{
				const a = [id, Array.from(dataMap)];
				a[1]
				.sort((a,b)=>a[0]-b[0])
				.forEach(([t, [sum, c]], i)=>{
					a[1][i][1] = (sum/c);
				});
				return a;
			})
		);
	}
}

function PrizeSimulation({graphType, simulationCount, attendance, settings}) {
	const [dataToGraph, setDataToGraph] = useState(null);
	const [graphElement, setGraphElement] = useState(null);

	const allPrizes = [];
	if(settings.type === 'scratch'){
		settings.prizes.forEach((prize)=>{
			allPrizes.push(prize);
		})
	}else{
		settings.slices.forEach((slice)=>{
			slice.prizes.forEach((prize)=>{
				allPrizes.push(prize);
			})
		})
	}

	useEffect(() => {
		if(graphElement && graphType && attendance && simulationCount){
			const t = new Test("TEST", settings);
			const tmp = attendance
				.map(([sD, eD, nU])=>[dateStringToNumber(sD), dateStringToNumber(eD), nU])
				.sort((a, b)=>a[0]-b[0])
			;
			for(let i=0;i<simulationCount;i++){
				t.run(tmp);
			}
			switch(graphType){
				case 'percentages':
					setDataToGraph(t.getPercentagesGraphData());
					break;
				case 'stockLeft':
					const gdata = t.getStockLeftGraphData()
					gdata.forEach( (value, key) => {
						if(key !== "total"){
							gdata.set(key, gdata.get(key).concat(gdata.get("total").slice(gdata.get(key).length, gdata.get("total").length)))
						}
					})
					setDataToGraph(gdata);
					break;
				default: setDataToGraph(null);
			}
		}else{
			setDataToGraph(null);
		}
	}, [graphType, graphElement, attendance, simulationCount])
	useEffect(() => {
		if(dataToGraph && graphElement) drawGraph(graphElement, dataToGraph, allPrizes, graphType==='percentages'?100:undefined);
	}, [dataToGraph, graphElement]);

	const measuredRef = useCallback(node => {
		setGraphElement(node);
	}, []);

	return (
		(!graphType || !attendance || !simulationCount)?
		<div>Missing settings</div>
		:
		<div ref={measuredRef} style={{height:'100%', width:'100%'}}></div>
	)
}

export default PrizeSimulation;

function drawGraph(node, dataToGraph, allPrizes, yMax){
	const myChart = echarts.init(node);

	const series = [];
	allPrizes.forEach((prize)=>{
		series.push({
			name: prize.text,
			type: 'scatter',
			symbolSize: 5,
			data: dataToGraph.get(prize.id)||[],
		})
	})
	if(dataToGraph.has("wins")) series.push({
		name: 'Wins',
		type: 'scatter',
		symbolSize: 7,
		data: dataToGraph.get("wins")||[],
	})
	if(dataToGraph.has("losses")) series.push({
		name: 'Losses',
		type: 'scatter',
		symbolSize: 7,
		data: dataToGraph.get("losses")||[],
	})
	if(dataToGraph.has("total")) series.push({
		name: 'Total',
		type: 'scatter',
		symbolSize: 7,
		data: dataToGraph.get("total")||[],
	})

	const option = {
		tooltip: {
			trigger: 'axis',
			position: (pt) => [pt[0], '10%']
		},
		toolbox: {
			feature: {
				dataZoom: {
					yAxisIndex: 'none'
				},
				// restore: {},
				// saveAsImage: {}
			}
		},
		xAxis: {
			type: 'time',
			boundaryGap: false
		},
		yAxis: {
			type: 'value',
			boundaryGap: [0, '100%'],
			max: yMax||'dataMax',
		},
		dataZoom: [
			{
				type: 'inside',
				start: 0,
				end: 100
			},
			{
				start: 0,
				end: 100
			}
		],
		legend: {},
		series: series,
	};

	myChart.setOption(option, true);
}

class InvalidStrategyError extends Error {
	constructor(campaignId, prize, message) {
		super(message);
		this.campaignId = campaignId;
		this.prize = prize;
	}
	/**
	 * @return {object}
	 *  {string} pageId (Optional) The page's id
	 *  {string} elementId The element id that was not found
	 */
	getDataObject() {
		return {"campaignId": this.campaignId, "prize": this.prize};
	}
}
function dateStringToNumber(date) {
	return new Date(date).getTime();
}
function randomInteger(min, max) {
	return Math.random() * (max - min) + min;
}
