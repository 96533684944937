import React, { useState, useEffect, useMemo } from 'react';
import { useLocalStorage } from "@uidotdev/usehooks";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import PrizeSimulation from './PrizeSimulation';

import "react-datepicker/dist/react-datepicker.css";

function GridRow({ i, startDate, endDate, expectedPeople, onChange, onRemove }) {
	return (
		
		<div className="flex w-full space-x-8 items-center">
			<div style={{ width: 142}}>
				<span className="invisible">-</span>
				<div>Range { i+1 } :</div>
			</div>
			<div>
				<span key={'sD' + i} className="flex items-center justify-start text-sm">Start Date:</span>
				{/* <input key={'sDI'+i} value={startDate} onChange={(event)=>onChange(i, 0, event.target.value)} className="p-2 rounded-md text-base" /> */}
				<DatePicker selected={startDate} showTimeSelect dateFormat="d MMMM, yyyy h:mm aa" onChange={(date) => onChange(i, 0, date)} />
			</div>

			<div>
				<span key={'eD' + i} className="flex items-center justify-start text-sm">End Date:</span>
				<DatePicker selected={endDate} showTimeSelect dateFormat="d MMMM, yyyy h:mm aa" onChange={(date) => onChange(i, 1, date)} />
			</div>

			<div>
				<span key={'eP' + i} className="flex items-center justify-start text-sm">Expected People:</span>
				<input type="text" key={'ePI' + i} value={expectedPeople} onChange={(event) => onChange(i, 2, event.target.value)} className="p-2 rounded-md text-base" />
			</div>
			<div>
				<div className="invisible">-</div>
				<button onClick={() => onRemove(i)} className="btn btn-primary text-base">Remove</button>
			</div>
		</div>

	)
}

function serializeAttendance(attendance){
	return JSON.stringify(attendance.map( item => {
		return [
			item[0].getTime(),
			item[1].getTime(),
			item[2]
		]
	}))
}

function parseAttendance(attendance){
	return JSON.parse(attendance).map( item => {
		return [
			new Date(item[0]),
			new Date(item[1]),
			item[2]
		]
	})
}

const graphOptions = [
	{
		value: 'stockLeft',
		label: 'Stock Left'
	},
	{
		value: 'percentages',
		label: 'Percentages'
	}
]

function PrizeSimulationModal({ currentElement, closeCb }) {
	const [graphType, saveGraphType] = useLocalStorage('graphType', 'stockLeft');
	const [simulationCount, saveSimulationCount] = useLocalStorage("simulationCount", 50);

	const [attendance, setAttendance] = useState([]);

	const setAttendanceValue = (i, j, value) => {
		attendance[i][j] = value;
		const tmp = [...attendance];
		setAttendance(tmp);
	};
	const addAttendance = () => {
		const afterLastDate = new Date(+attendance[attendance.length-1][1] + 86400000);
		const tmp = [...attendance, [afterLastDate, new Date(+afterLastDate + 86400000), 50]];
		setAttendance(tmp);
	};
	const removeAttendance = (index) => {
		if(attendance.length > 1) {
			setAttendance(attendance.filter((a, i) => {
				return i !== index;
			}));
		}
	};

	const [ hasInfStock, hasBadMix] = useMemo( () => {
		const allPrizes = [];
		if(currentElement.type === 'scratch'){
			currentElement.prizes.forEach((prize)=>{
				allPrizes.push(prize);
			})
		}else{
			currentElement.slices.forEach((slice)=>{
				slice.prizes.forEach((prize)=>{
					allPrizes.push(prize);
				})
			})
		}

		let infStockCount = 0;
		let hasFiniteAuto = false;
		let hasInfiniteAuto = false;
		allPrizes.forEach( prize => {
			if(prize.stock == null) infStockCount++;
			if(prize.stock != null && prize.probability == null) hasFiniteAuto = true;
			if(prize.stock == null && prize.probability == null) hasInfiniteAuto = true;
		})

		return [
			infStockCount > 0,
			hasFiniteAuto && hasInfiniteAuto
		]
	}, [currentElement])
	

	useEffect(() => {
		const attendance = localStorage.getItem("attendance")
		if(attendance) {
			setAttendance(parseAttendance(attendance));
		}
		else {
			const initAttendance = [
				[new Date(), new Date(+new Date() + 86400000), 200],
			]
			setAttendance(initAttendance)
		}
	}, [])

	useEffect(() => {
		if(attendance){
			localStorage.setItem("attendance", serializeAttendance(attendance))
		}
	}, [attendance])
	

	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated fadeIn modal--sm" style={{ width: '95%', maxWidth: '95%', height: '95%', maxHeight: '95%' }}>
				<div className="modal__header">
					<h2 className="modal__header-title mx-8 my-4">Prize Simulation</h2>
					<div className="close-modal modal--x" onClick={() => closeCb(false)}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content" style={{display: 'flex', flexDirection: 'column', marginTop: 0}}>
					<div className="modal__body text--center" style={{ display: 'block', padding: '10px 40px', maxHeight: 'calc(100vh - 200px)'}}>
						<div className="space-y-4">
							<div style={{ "display": "grid", "gridTemplateColumns": "auto 1fr", "gap": "15px 10px" }} className="mt-8">
								<div className="text-left flex items-center">Graph Type:</div>
								<Select
									options={graphOptions}
									value={graphOptions.find( o => o.value === graphType )}
									className="text-left"
									classNamePrefix="select"
									onChange={option => saveGraphType(option.value)}
								/>
								<div className="flex items-center">Simulation Count:</div>
								<input type="text" value={simulationCount} onChange={(event) => {
									if (event.target.value) saveSimulationCount(event.target.value)
								}} className="p-2 rounded-md text-base" />
							</div>
							<div className="space-y-4">
								{attendance.map(([startDate, endDate, expectedPeople], i) => {
									return <GridRow i={i} startDate={startDate} endDate={endDate} expectedPeople={expectedPeople} onChange={setAttendanceValue} onRemove={removeAttendance} key={i} />;
								})}
							</div>
							<div>
								<div className="mt-8"></div>
								<button onClick={addAttendance} className="btn btn-primary text-base">Add Range</button>
							</div>
						</div>
						<div className="mt-8 space-y-2">
							{ graphType === "stockLeft" && hasInfStock &&
								<div className="text-sm">
									<span className="material-icons text--warning font--25 mright--10">
										warning_amber
									</span>
									One or more of the prizes have infinite stock, so they are not visible in the "Stock Left" Graph. Try the percentages graph.
								</div>
							}
							{ hasBadMix &&
								<div className="text-sm">
									<span className="material-icons text--warning font--25 mright--10">
										warning_amber
									</span>
									
										Attention: There is a mix of prizes with probability auto, while one of them has infinite stock. <br />
										As a result the prize with limited stock will never be won. Try assigning probabilities or set a limited stock.
									
								</div>
							}
						</div>
						<div style={{ height: '350px', width: '100%' }} className="mt-10" >
							<PrizeSimulation
								settings={currentElement}
								graphType={graphType}
								simulationCount={simulationCount}
								attendance={attendance}
							/>
						</div>
					</div>
					<div className="modal__footer text--right ">
						<button className="btn btn-secondary" onClick={() => closeCb(false)}>Close</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PrizeSimulationModal