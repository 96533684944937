import { useEffect, useState } from "react";
import "./scss/QuizResults.scss"
import "./scss/Poll.scss"

function QuestionResults({questions}) {
	return (<div>
		{questions.map(element=>{
			return (<div key={element.id} className="poll poll__app">
			{element.question && <div className="poll__header" dangerouslySetInnerHTML={{ __html: element.question }} />}
				<div className="mtop--10 flex flex--column">
					{element?.options.map(({id, text, isSelected, isCorrect, isProblem}) => {
						let className = "poll__element";
						if(isSelected) className += " isSelected";
						if(isCorrect) className += " isCorrect";
						if(isProblem) className += " isProblem";
						return (<div key={id} className={className}>
							<div className="status"></div>
							{(isCorrect || isProblem) && <span className="material-icons mright--10">{isCorrect?"check":"close"}</span>}
							<span className="vm--align">{text}</span>
						</div>)
					})}
				</div>
			</div>)
		})}
	</div>);
}

function QuizResults({data, getQuizResults}) {
	const marginTop = data.marginTop;
	const background = data.background;
	const showCorrectQuestionCount = data.showCorrectQuestionCount;
	const preHtml = data.preHtml;
	const postHtml = data.postHtml;
	const html = data.html;
	const showCorrectAnswers = data.showCorrectAnswers;
	const [quizData, setQuizData] = useState(null);

	useEffect(()=>{
		getQuizResults()
		.then((data)=>{
			setQuizData(data);
		})
		.catch((error)=>{
			console.error(error);
			setQuizData(null);
		})
	}, [getQuizResults]);

	return (
		<div className="QuizResults" style={{marginTop, background, padding: "5px"}}>
			{quizData && <>
				{showCorrectQuestionCount && <div className="countContainer">
					<span dangerouslySetInnerHTML={{__html: preHtml}}></span>
					<span className="count">&nbsp;{quizData?.counts?.correct} / {quizData?.counts?.questions}&nbsp;</span>
					<span dangerouslySetInnerHTML={{__html: postHtml}}></span>
				</div>}
				<div className="message" dangerouslySetInnerHTML={{__html: html}}></div>
				{showCorrectAnswers && <QuestionResults questions={quizData.questions}/>}
			</>}
			{!quizData && <div>Loading...</div>}

		</div>
	);
};
export default QuizResults;
