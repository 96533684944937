import { useState, useEffect } from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import languageCodeToCountryCode from './languageToCountryCodes.json';

function FormFieldPhone({ data, value, sending, elementErrors, onChange }) {
	const [err, setErr] = useState(false);
	if (value === undefined) value = false;
	const required = data.required;

	function setData(e) {
		setErr(false);
		console.log('* FormFieldPhone sends:', data.id, e)
		onChange(data.id, e ? e : null);
	}
	const getInputStyling = () => {
		let className = "FormFieldPhone md__input mtop--15";
		if (err) className += ' input--error';
		return className;
	}
	const getDefaultCountry = () => {
		if (data.defaultCountry) {
			return data.defaultCountry
		} else {
			const languageCode = navigator.language
			if (languageCodeToCountryCode.hasOwnProperty(languageCode)) {
				return languageCodeToCountryCode[languageCode];
			}
			return languageCode.toUpperCase();
		}
	}
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])

	return (
		<div className={getInputStyling()}>
			{data.label && data.label}
			{required ? <b className="text--danger">*</b> : ''}
			<div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
			<PhoneInput
				placeholder={data.placeholder ? data.placeholder : 'Enter your phone number...'}
				value={value ? value : ''}
				defaultCountry={getDefaultCountry()}
				onChange={setData} />
			{elementErrors?.errors.map((error) => <div className="text--danger mtop--10">{error.message}</div>)}
		</div>
	);
};
export default FormFieldPhone;
