import "./scss/SubmitButton.scss";

function SubmitButton({ data, sending, onSubmit }) {
	return (
		<div style={{ marginTop: data?.marginTop, textAlign: data?.textAlign, marginLeft: 'auto', marginRight: 'auto' }} className={"submitButtonContainer"}>
			<button disabled={sending} style={{
				background: data?.background,
				color: data?.textColor,
				width: data?.width === 'full' ? '100%' : '',
				fontSize: data?.fontSize ? data.fontSize : '',
				margin: 0,
			}} onClick={onSubmit} className="btn submitButton text--bold">
				{!sending && <span style={{fontSize: '1.26em'}}>{data?.text}</span>}
				{sending && "Saving..."}
			</button>
		</div>
	);
};
export default SubmitButton;
