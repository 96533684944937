import { useState } from "react";
import "./scss/SoccerLineUpSelector.scss";
import SoccerLineUpSelectorPlayer from "./SoccerLineUpSelectorPlayer";

function SoccerLineUpSelector({ data, value, sending, elementErrors, onChange }) {
	const [response, setResponse] = useState(mapFormationToResponse(data.formations[0]));
	const [groupedPlayers] = useState(mapPlayersToCategories())

	console.log('~SoccerLineUpSelector', data.id, value, elementErrors)
	const setData = (position, index, player_id) => {
		console.log(response, position, index, player_id)
		const o = { ...response };
		o[position] = o[position].map(p => {
			if (p.position === index) {
				p.playerId = player_id
			}
			return p
		})
		onChange(data.id, o);
		setResponse(o);
	};

	const { formations, shirtColor, shirtImage, gkShirtColor, gkShirtImage } = data;
	const formation = formations[0]
	const getPosition = (position, index, errors) => {
		let arr = formation[position];
		let o = <div key={`${position}_${index}`} className="formation__grid-column"></div>;
		if (arr) {
			arr.forEach(p => {
				if (p === index) {
					o = <SoccerLineUpSelectorPlayer
						key={`${position}_${index}`}
						shirtColor={position !== 'goal' ? shirtColor : gkShirtColor}
						shirtImage={position !== 'goal' ? shirtImage : gkShirtImage}
						position={position}
						index={index}
						players={data?.players}
						groupedPlayers={groupedPlayers}
						currentMap={response}
						setData={setData}
						errors={errors}
					/>
				}
			})
		}
		return o;
	}
	const generateLineUp = (position, errors) => {
		console.log(errors);
		return (
			<div className="formation__grid-row" key={`position__${position}`}>
				<div className="formation__grid-rowSub">
					{
						(() => {
							const rows = [];
							for (let i = 0; i < 5; i++) {
								rows.push(getPosition(position, i, errors));
							}
							return rows;
						})()
					}
				</div>
				{(position!=='goal')&&<div className="formation__grid-rowSub">
					{
						(() => {
							const rows = [];
							for (let i = 5; i < 10; i++) {
								rows.push(getPosition(position, i, errors));
							}
							return rows;
						})()
					}
				</div>}
			</div>
		)
	}
	function mapFormationToResponse(formation) {
		const o = {};
		Object.keys(formation).forEach((key) => {
			const typeOfKey = typeof formation[key];
			if (typeOfKey === 'string') {
				o['formation'] = formation[key];
			} else {
				o[key] = formation[key].map((position) => {
					return {
						'position': position,
						'playerId': null
					}
				})
			}
		});
		return o;
	}
	function mapPlayersToCategories() {
		const o = [
			...data.categories.map((category) => {
				return {
					...category, ...{
						players: data.players.filter(player => {
							return player.categories.find(ct => ct === category.id)
						})
					}
				}
			}),
			...[{ title: 'GENERAL POSITION', players: data.players.filter(player => player.categories.length === 0) }]
		]
		return o
	}

	return (
		<div style={{ marginTop: data?.marginTop }} className="lineup">
			<div className="js-stage stage texture">
				<div className="js-world world">
					<div className="terrain js-terrain">
						<div className="field ground">
							<div className="formation__grid">
								{generateLineUp('ext', elementErrors?.ext)}
								{generateLineUp('fwd', elementErrors?.fwd)}
								{generateLineUp('mid', elementErrors?.mid)}
								{generateLineUp('def', elementErrors?.def)}
								{generateLineUp('goal', elementErrors?.goal)}
							</div>
							<div className="field__texture field__texture--gradient"></div>
							<div className="field__texture field__texture--gradient-b"></div>
							<div className="field__texture field__texture--grass"></div>
							<div className="field__line field__line--goal"></div>
							<div className="field__line field__line--goal field__line--goal--far"></div>
							<div className="field__line field__line--outline"></div>
							<div className="field__line field__line--penalty"></div>
							<div className="field__line field__line--penalty-arc"></div>
							<div className="field__line field__line--penalty-arc field__line--penalty-arc--far"></div>
							<div className="field__line field__line--mid"></div>
							<div className="field__line field__line--circle"></div>
							<div className="field__line field__line--penalty field__line--penalty--far"></div>
						</div>
					</div>
				</div>
			</div>
			{elementErrors &&
				<>
					{elementErrors?.errors?.map((error, idx) => <div className="text--danger" key={`error_${idx}`}>
						{error.message === 'This field is required to continue' ? 'Please select all 11 players' : error.message}
					</div>)}
				</>
			}
		</div>
	);
};
export default SoccerLineUpSelector;
