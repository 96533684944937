import "./scss/Mvp.scss";
function Mvp({ data, value, sending, elementErrors, onChange }) {
	console.log('~Mvp', data.id, value, elementErrors)
	console.log(data)
	if (!Array.isArray(value)) value = [];
	const setData = (newValue) => {
		let dt = [...value];
		const index = dt.indexOf(newValue);
		if (index < 0) {
			if (data.max === 1) {
				dt = [newValue];
			} else {
				dt.push(newValue)
			}
		} else {
			dt.splice(index, 1);
		}
		console.log('* Mvp sets and sends:', data.id, value, '->', dt)
		onChange(data.id, dt);
	};
	const getStyling = () => {
		return {
			marginTop: data?.marginTop,
			background: data?.background,
			color: data?.textColor,
			borderColor: `${data?.textColor} !important`,
			borderRadius: '4px',
			padding: '5px',
		}
	}
	const getOptionStyling = () => {
		return {
			background: data?.optionsBackgroundColor,
			color: data?.optionsTextColor,
		}
	}
	return (
		<div style={getStyling()} className="poll">
			{data.question && <div className="poll__header" dangerouslySetInnerHTML={{ __html: data.question }} />}
			{data.layout === "row" &&
				<div className="mvp-container mvp-containerRow">
					{data?.options.map((option, index) => {
						const selected = value.includes(option.id);
						return (
							<div style={getOptionStyling()} key={`option__${index}`} className={"mvp" + (selected ? " mvp-selected" : "")} onClick={() => setData(option.id)}>
								<span className="material-icons font--25 check">{selected ? 'radio_button_checked' : 'radio_button_unchecked'}</span>
								<img src={option.image} className="img__responsive" />
								<div className="mvp__info" >
									<div className="mvp__info-text">{option.showText === false ? '' : option.text}</div>
									<div className="mvp__info-attributes">
										{option?.attributes?.map((attribute, idx) => {
											return (
												<div className="mvp__info-attributedOption" key={`attribute__mvp-${idx}`}>
													<b className="mvp__info-attributedOptionCounter">{attribute['k']}</b>
													<span className="mvp__info-attributedOptionText">{attribute['v']}</span>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			}
			{data.layout === "column" &&
				<div className="mvp-container mvp-containerColumn">
					{data?.options.map((option, index) => {
						const selected = value.includes(option.id);
						return (
							<div style={getOptionStyling()} key={`option__${index}`} className={"mvp" + (selected ? " mvp-selected" : "")} onClick={() => setData(option.id)}>
								<span className="material-icons font--25 check">{selected ? 'radio_button_checked' : 'radio_button_unchecked'}</span>
								<img src={option.image} className="img__responsive" />
								<div className="mvp__info" >
									<div className="mvp__info-text">{option.showText === false ? '' : option.text}</div>
									<div className="mvp__info-attributes">
										{option?.attributes?.map((attribute, idx) => {
											return (
												<div className="mvp__info-attributedOption" key={`attribute__mvp-${idx}`}>
													<b className="mvp__info-attributedOptionCounter">{attribute['k']}</b>
													<span className="mvp__info-attributedOptionText">{attribute['v']}</span>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			}
			{elementErrors?.errors.map((error, idx) => <div key={`error_${idx}`} className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default Mvp;
