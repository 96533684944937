import "./scss/CategorizedPoll.scss";
function CategorizedPoll({data, value, sending, elementErrors, onChange}) {
    console.log('~CategorizedPoll', data.id, value, elementErrors)
	const setData = (id, newValue) => {
		console.log('* CategorizedPoll sets and sends:', data.id, value, '->', {...value, [id]:newValue})
		onChange(data.id, {...value, [id]:newValue});
	};
	return (
		<div style={{marginTop:data?.marginTop}} className="poll">

            {
                data.categories.map((category, index) => {
                    return (
                        <div key={`category_${index}`} className="categorized__category">
                            <div className="categorized__category-header" style={{background: data?.background, color: data?.color}}>
                                {category.title}
                            </div>
                            { data.layout == 0 &&
                                <div className="flex flex--row flex--wrap justify--center">
                                    {category.options.map((option, index) => {
                                        return (
                                            <div key={index} className="categorized__poll" onClick={() => setData(data.id, option.id)}>
                                                <img src={option.image} className="img__responsive" />
                                                <div>{option.text}</div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            }
                            { data.layout == 1 &&
                                <div className="flex flex--row flex--wrap justify--center">
                                    {category.options.map((option, index) => {
                                        return (
                                            <div key={index} className="categorized__poll" onClick={() => setData(data.id, option.id)}>
                                                <div>{option.text}</div>
                                                <img src={option.image} className="img__responsive" />
                                            </div>
                                        )
                                    }) }
                                </div>
                            }
                            { data.layout == 2 &&
                                <div className="flex flex--column justify--center">
                                    {category.options.map((option, index) => {
                                        return (
                                            <div key={index} className="categorized__poll categorized__poll-selected" onClick={() => setData(data.id, option.id)}>
                                                <div>{option.text}</div>
                                                <img src={option.image} className="img__responsive" />
                                            </div>
                                        )
                                    }) }
                                </div>
                            }
                        </div>
                    )
                })
            }
		</div>
	);
};
export default CategorizedPoll;
