import "./scss/RegistrationForm.scss";
import FormFieldInput from './FormFieldInput.jsx'
import FormFieldCheckbox from './FormFieldCheckbox.jsx'
import FormFieldChoiceOptions from './FormFieldChoiceOptions.jsx'
import FormFieldDate from './FormFieldDate.jsx'
import FormFieldPhone from './FormFieldPhone.jsx'
import FormFieldSignature from './FormFieldSignature.jsx'
import FormFieldNumeric from './FormFieldNumeric.jsx'
function RegistrationForm({ data, value, sending, elementErrors, onChange, onSubmit, globalStyling, autofill }) {
	console.log('~RegistrationForm', data.id, value, elementErrors)
	const setData = (id, newValue) => {
		console.log('* RegistrationForm sets and sends:', data.id, value, '->', { ...value, [id]: newValue })
		onChange(data.id, { ...value, [id]: newValue });
	};
	const getFormStyling = () => {
		return {
			marginTop: data?.marginTop,
			background: data?.background,
			color: data?.textColor,
		};
	}
	const getSubmitStyling = () => {
		return {
			background: globalStyling?.primaryColor,
			color: globalStyling?.secondaryTextColor
		}
	}
	const inputDisabled = () => {
		if (sending) return sending;
		let atLeastOneRequiredNotFilled = false;
		data.fields.map((field) => {
			if (field.required) {
				if (!value?.[field.id]) atLeastOneRequiredNotFilled = true;
			}
		})
		return atLeastOneRequiredNotFilled;
	}
	return (
		<div style={getFormStyling()} className="registration">
			{data.header && <div key="header" dangerouslySetInnerHTML={{ __html: data.header }} />}
			{data.fields && data.fields.map(function (field) {
				switch (field.type) {
					case 'firstName':
					case 'lastName':
					case 'text':
					case 'email':
						return <FormFieldInput autofill={autofill} key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'checkbox':
						return <FormFieldCheckbox key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'choice':
						return <FormFieldChoiceOptions key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'date':
						return <FormFieldDate key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'tel':
						return <FormFieldPhone key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'signature':
						return <FormFieldSignature key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					case 'ssn':
						return <FormFieldNumeric autofill={autofill} key={field.id} data={field} value={value?.[field.id]} sending={sending} elementErrors={elementErrors?.[field.id]} onChange={setData} />;
					default:
						console.error('Invalid type', field.type);
						return <></>;
				}
			})}
			<button disabled={inputDisabled()} onClick={onSubmit} style={getSubmitStyling()} className="btn btn-primary btn-100 btn-lg text--bold mtop--15">
				{!sending && data?.submitText}
				{sending && "Saving..."}
			</button>
			{data.footer && <div key="footer" dangerouslySetInnerHTML={{ __html: data.footer }} />}
		</div>
	);
};
export default RegistrationForm;
